/* Conteneur principal en pleine largeur avec un padding */
.profile-page-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 100px;
}

.profile-page-container h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

/* Conteneur centralisant les sections avec une largeur maximale */
.profile-sections {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Style général de chaque section */
.profile-section {
  background: #1b1b1b;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Titres des sections */
.profile-section h3 {
  margin-top: 0;
  margin-bottom: 20px;
}

/* Formulaire de la section Compte */
.profile-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Aperçu de l'avatar */
.avatar-preview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

/* Boutons communs */
.update-button,
.manage-subscription-btn,
.change-password-button,
.contact-support-button,
.delete-account-button {
  margin-top: 10px;
  padding: 10px;
  background-color: #1E88E5;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  align-self: start;
}


.update-button:hover,
.manage-subscription-btn:hover,
.change-password-button:hover,
.contact-support-button:hover {
  background-color: #1661a3;
}

/* Bouton de suppression de compte (action risquée) */
.delete-account-button {
  background-color: #ff4d4f;
}

.delete-account-button:hover {
  background-color: #ff7875;
}

/* Message de date d'annulation */
.cancel-date-message {
  color: orange;
  text-align: center;
  margin-top: 10px;
}

/* Optionnel : adaptation des inputs pour qu'ils s'ajustent en responsive */
.profile-form input[type="text"],
.profile-form input[type="email"] {
  width: 100%;
  max-width: 400px;
  padding: 8px;
  box-sizing: border-box;
}

/* Conteneur pour les infos d'abonnement */
.subscription-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Chaque ligne d'info avec label et conteneur */
.subscription-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Optionnel : fixer une largeur minimale pour aligner les labels */
.subscription-item label {
  min-width: 150px;
  color: #fff;
}

/* Style du conteneur affichant l'info d'abonnement */
.subscription-info {
  padding: 5px 10px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
}

.delete-account-section {
  margin-top: 20px; /* Ajustez la valeur selon vos besoins */
}