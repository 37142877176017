/* ConfirmationModal.css */

.confirmation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirmation-modal {
    background-color: #313131;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
  }
  
  .confirmation-modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }

  .confirmer{
    background-color: #1E88E5;
    color: #FFFFFF;
    font-weight: 700;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirmer:hover {
    background-color: #1766ac;
  }