/* src/styles/FeaturesPage.css */

.features-page {
  background-color: #121212;
  min-height: 100vh;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

.features-container {
  max-width: 900px;
  margin: 80px auto 30px; /* Laisse la place pour le header fixe */
  padding: 20px;
}

.features-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Section des cards en mode empilé : on ne nécessite plus de flexbox */
.feature-cards-section {
  display: block;
  margin-bottom: 40px;
}

/* Mises à jour et Notes de patch */
.updates-section,
.patch-notes-section {
  margin-bottom: 30px;
}

.feature-heading {
  font-size: 1.4rem;
  color: #1E88E5;
  margin-bottom: 10px;
}

.feature-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #ccc;
  margin-bottom: 10px;
}

.feature-list {
  list-style: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.feature-section.emphasis {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
}

.features-container .phone-feature-section {
  /* mêmes règles que définies initialement dans HomePage.css */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 60px 20px;
  opacity: 0;
  transform: translateX(-100px);
  transition: all 1s ease-in-out;
  max-width: 1000px;
  margin: 0 auto;
}
.features-container .phone-feature-section.phone-visible {
  opacity: 1;
  transform: translateX(0);
}

/* Responsive */
@media (max-width: 600px) {
  .features-container {
    margin: 60px auto 20px;
    padding: 10px;
  }
  .features-title {
    font-size: 1.6rem;
  }
  .feature-heading {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  .feature-text {
    font-size: 0.95rem;
  }
}