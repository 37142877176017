/* Variables de Couleurs pour une Gestion Facile */
:root {
    --color-background: #1e1e2c;
    --color-input-bg: #2f2f44;
    --color-input-focus: #3f3f55;
    --color-text: #ffffff;
    --color-secondary-text: #cccccc;
    --color-error: #ff4b4b;
    --color-error-bg: #ffe5e5;
    --color-success: #4caf50;
    --color-success-bg: #e5ffe5;
    --color-button: #149bd0;
    --color-button-hover: #09729b;
    --color-link: #149bd0;
    --color-link-hover: #09729b;
    --color-border: #444;
  }
  
  /* Conteneur Principal */
  .auth-container {
    background-color: var(--color-background);
    color: var(--color-text);
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Titre */
  .auth-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Formulaire */
  .auth-container form {
    width: 100%;
    max-width: 400px;
    background-color: #242438;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  
  /* Champs de Formulaire */
  .auth-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
  }
  
  .password-input-container {
    position: relative;
    margin-bottom: 20px;
  }
  
  .password-input-container input {
    width: 100%;
    padding: 12px 40px 12px 15px;
    border: 1px solid var(--color-border);
    border-radius: 5px;
    background-color: var(--color-input-bg);
    color: var(--color-text);
    font-size: 1rem;
    transition: background-color 0.3s ease, border 0.3s ease;
  }
  
  .password-input-container input:focus {
    background-color: var(--color-input-focus);
    border: 1px solid var(--color-link);
    outline: none;
  }
  
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: var(--color-secondary-text);
    cursor: pointer;
    font-size: 1.2rem;
    transition: color 0.3s ease;
  }
  
  .password-toggle-icon:hover {
    color: var(--color-text);
  }
  
  /* Bouton de Soumission */
  .auth-container button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: var(--color-button);
    color: var(--color-text);
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-container button:hover {
    background-color: var(--color-button-hover);
  }
  
  .auth-container button:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
  
  /* Messages d'Erreur et de Succès */
  .auth-message {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 0.9rem;
  }
  
  .auth-message.error {
    color: var(--color-error);
    background-color: var(--color-error-bg);
  }
  
  .auth-message.success {
    color: var(--color-success);
    background-color: var(--color-success-bg);
  }
  
  /* Critères de Mot de Passe */
  .password-criteria {
    font-size: 0.85rem;
    color: var(--color-secondary-text);
    margin-top: 5px;
  }
  
  /* Lien Mot de Passe Oublié */
  .forgot-password {
    font-size: 0.85rem;
    margin-top: 10px;
    text-align: center;
    color: var(--color-secondary-text);
    transition: opacity 0.3s ease, height 0.3s ease;
  }
  
  .forgot-password.hidden {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
  
  .forgot-password-link {
    color: var(--color-link);
    cursor: pointer;
    margin-left: 5px;
    text-decoration: underline;
    transition: color 0.3s ease;
  }
  
  .forgot-password-link:hover {
    color: var(--color-link-hover);
  }
  
  /* Liens de Navigation */
  .switch-auth-link {
    margin-top: 20px;
    text-align: center;
  }
  
  .switch-auth-link a {
    color: var(--color-link);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .switch-auth-link a:hover {
    color: var(--color-link-hover);
  }
  
  /* Animations pour les Champs */
  .field-container {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease, max-height 0.5s ease;
  }
  
  .field-container.fade-in {
    opacity: 1;
    max-height: 500px;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .auth-container form {
      padding: 20px;
    }
  
    .auth-container h2 {
      font-size: 1.5rem;
    }
  
    .auth-container button {
      padding: 10px;
      font-size: 0.95rem;
    }
  
    .password-toggle-icon {
      font-size: 1rem;
    }
  }