.goodbye-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
}

.goodbye-container h2 {
  color: #ff4d4f;
}

.goodbye-container a {
  margin-top: 20px;
  color: #1890ff;
  text-decoration: none;
  font-weight: bold;
}

.goodbye-container a:hover {
  text-decoration: underline;
}
