/* src/styles/ComponentsStyles/MinimalLogo.css */
.minimal-logo-container {
    text-decoration: none;
    cursor: pointer; 
  }
  
  .minimal-logo-title {
    font-size: 1.8rem;
    font-weight: 700;
  }
  
  /* Responsive : réduire la taille sur petits écrans */
  @media (max-width: 768px) {
    .minimal-logo-title {
      font-size: 1.4rem;
    }
  }