.home-footer {
    width: 100%; /* Assure que le footer occupe toute la largeur */
    background-color: var(--footer-bg);
    color: var(--secondary-text-color);
    padding: 40px 20px;
    text-align: center;
  }
  
  /* === Liens et colonnes === */
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    margin-bottom: 20px;
  }
  
  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 150px;
  }
  
  .footer-col-title {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #fff;
  }
  
  .footer-link {
    color: var(--secondary-text-color);
    text-decoration: none;
    margin-bottom: 6px;
    transition: color var(--transition-default);
  }
  
  .footer-link:hover {
    color: #999;
  }
  
  /* --- Style pour l'icône Instagram --- */
  .footer-icon-link {
    display: inline-flex;
    align-items: center;
  }
  
  .footer-icon {
    font-size: 20px;
    color: var(--secondary-text-color);
    transition: color var(--transition-default);
    margin-right: 8px; /* Espace entre l'icône et le texte */
  }
  
  .footer-icon-link:hover .footer-icon {
    color: #999;
  }
  
  /* === Mention Spotify === */
  .footer-powered-by {
    font-size: 0.9rem;
    color: var(--secondary-text-color);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  /* === Copyright === */
  .footer-copy {
    font-size: 0.85rem;
    color: var(--secondary-text-color);
  }
  
  /* ===== Responsive pour téléphone ===== */
  @media (max-width: 480px) {
    .home-footer {
      padding: 20px 10px;
    }
    
    /* Passage en grille à 2 colonnes pour réorganiser les rubriques */
    .footer-links {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    
    /* Réorganisation des rubriques :
       - 1er enfant (Contact) : première ligne, première colonne
       - 4ème enfant (Compte) : première ligne, deuxième colonne
       - 2ème enfant (Liens) : deuxième ligne, première colonne
       - 3ème enfant (Aide) : deuxième ligne, deuxième colonne
    */
    .footer-links .footer-column:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
      align-items: center;
      text-align: center;
    }
    
    .footer-links .footer-column:nth-child(4) {
      grid-column: 2;
      grid-row: 1;
      align-items: center;
      text-align: center;
    }
    
    .footer-links .footer-column:nth-child(2) {
      grid-column: 1;
      grid-row: 2;
      align-items: center;
      text-align: center;
    }
    
    .footer-links .footer-column:nth-child(3) {
      grid-column: 2;
      grid-row: 2;
      align-items: center;
      text-align: center;
    }
    
    .footer-col-title {
      font-size: 1rem;
    }
    
    .footer-link {
      font-size: 0.8rem;
      margin-bottom: 4px;
    }
    
    .footer-powered-by {
      font-size: 0.8rem;
      margin-bottom: 10px;
    }
    
    .footer-copy {
      font-size: 0.75rem;
    }
  }