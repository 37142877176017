/* src/styles/ComponentsStyles/Layout.css */

/* Conteneur principal du layout */
.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Permet d'occuper toute la hauteur de la fenêtre */
}

/* Zone principale qui contient le contenu de la page */
.main-content {
  flex: 1; /* Permet au contenu de s'étendre et de pousser le footer vers le bas */
  width: 100%;
  /* Vous pouvez ajouter ici d'autres styles (marges, padding, etc.) pour le contenu */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.content {
  background-color: #1b1b1b;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  width: 90%;
}

/* Modal global pour les mises à jour */
.updates-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Par-dessus tout le reste */
  padding: 1rem; /* Pour un peu d'espace sur les écrans très petits */
}

/* Contenu du modal */
.updates-modal-content {
  background-color: #1b1b1b;
  padding: 20px;
  border-radius: 8px;
  text-align: center; /* On garde le titre centré */
  max-width: 900px;
  width: 90%; /* Responsive : occupe 90% de la largeur de l'écran sur mobile */
  color: #fff; /* Assure une bonne lisibilité du texte */
}

/* Alignement à gauche et espacement des paragraphes dans le modal */
.updates-modal-content p {
  text-align: left;
  margin-bottom: 1rem; /* Espace entre les paragraphes */
}

/* Style des boutons dans le modal */
.updates-modal-content button {
  background-color: #1E88E5; /* Couleur de fond verte */
  border: none;
  color: #fff;
  padding: 10px 20px;
  margin: 10px 5px; /* Espace entre les boutons */
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.updates-modal-content button:hover {
  background-color: #1361a5;
}

.updates-modal-content ul {
  text-align: left;      /* Aligne le texte des listes à gauche */
  margin: 1rem 0;        /* Ajoute un espace au-dessus et en dessous */
  padding-left: 1.5rem;  /* Indente les bullet points */
}

/* Pour améliorer la réactivité sur les très petits écrans */
@media (max-width: 480px) {
  .updates-modal-content {

    padding: 15px;
    font-size: 0.9rem;
margin-top: 80px;

    max-height: 60vh;       /* Limite la hauteur à 90% de la fenêtre */
    overflow-y: auto;       /* Permet le défilement si le contenu dépasse */
  }

  .updates-modal-content h2 {
    font-size: 1.2rem;      /* Ajustement de la taille du titre */
  }

  .updates-modal-content ul {
    padding-left: 1rem;     /* Réduit légèrement l'indentation des listes */
  }

  .updates-modal-content button {
    padding: 8px 16px;      /* Réduction de la taille des boutons */
    font-size: 0.9rem;
  }
}