/* -------------------------------- */
/*        Import & Variables        */
/* -------------------------------- */
/* Si tu utilises déjà @import dans SearchPage.css,
   tu peux soit le dupliquer, soit mettre l'import
   global dans un fichier central. */
   @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

   /* Copie-colle les mêmes variables que dans SearchPage.css */
   :root {
     --background-color: #1b1b1b;
     --card-background-color: #1e1e1e;
     --card-hover-bg: #252525;
     --text-color: #ffffff;
     --secondary-text-color: #cccccc;
     --status-text-color: #b0b0b0;
     --button-primary-bg: #1E88E5;
     --button-primary-hover-bg: #1565c0;
     --button-propose-hover-bg: #ffffff;
     --button-propose-hover-text: #000000;
     --cooldown-bg: #333;
     --cooldown-text: #d8d8d8;
     --cooldown-bar-bg: #1E88E5;
     --cooldown-bar-hover-bg: #1565C0;
     --modal-overlay-bg: rgba(0, 0, 0, 0.6);
     --modal-content-bg: #222;
     --alert-success-bg: #1E5128;
   
     --card-border-radius: 8px;
     --button-border-radius: 4px;
     --search-input-border-radius: 4px;
   
     --font-family: 'Poppins', sans-serif;
     --font-family-bold: 'Poppins-bold', sans-serif;
   
     --box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
     --transition-default: 0.3s ease;
     --transition-smooth: 0.5s ease;
   }
   
   /* Remise à zéro basique */
   * {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
   }
   
   /* -------------------------------- */
   /*       Structure principale       */
   /* -------------------------------- */
   .endofevent-container {
     background-color: var(--background-color);
     color: var(--text-color);
     min-height: 100vh;
     width: 100%;
    
     /* Pour ressembler à la SearchPage, on peut centrer verticalement : */
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     /* Ajuste le padding si nécessaire */
     padding: 40px 20px;
     text-align: center;
     font-family: var(--font-family);
   }
   
   /* Gestion du chargement */
   .loading-message {
     color: var(--text-color);
     font-size: 1rem;
     margin-top: 50px;
   }
   
   /* Titre principal */
   .endofevent-title {
     font-size: 2rem;
     font-weight: 700;
     margin-bottom: 20px;
     color: var(--text-color);
   }
   
   /* Paragraphe du message de fin */
   .endofevent-message {
     margin-top: 20px;
     line-height: 1.5em;
     font-size: 1rem;
     color: var(--secondary-text-color);
     max-width: 700px; /* Ajuste si tu veux limiter la largeur du texte */
     text-align: center;
   }
   
   /* Conteneur pour les sections "Instagram" ou "Site web" */
   .endofevent-section {
     margin-top: 40px;
   }
   
   /* Titre de section (facultatif si tu veux styliser) */
   .endofevent-section-title {
     font-size: 1rem;
     font-weight: 600;
     margin-bottom: 10px;
     color: var(--text-color);
   }
   
   /* Liens Instagram / Site */
   .endofevent-link {
     color: var(--button-primary-bg);
     text-decoration: underline;
     font-size: 0.95rem;
     transition: color var(--transition-default);
   }
   
   .endofevent-link:hover {
     color: var(--button-primary-hover-bg);
   }
   
   /* Exemple de styling supplémentaire */
.instagram-link i {
    font-size: 2rem;            /* Ajuste la taille de l’icône */
    color: var(--button-primary-bg);
    transition: color 0.3s ease;
    margin: 0 8px; /* un petit espace éventuellement */
  }
  
  .instagram-link:hover i {
    color: var(--button-primary-hover-bg);
  }
  
   /* -------------------- */
   /*    Responsiveness    */
   /* -------------------- */
   @media (max-width: 768px) {
     .endofevent-title {
       font-size: 1.6rem;
     }
     .endofevent-message {
       font-size: 0.95rem;
       max-width: 90%;
     }
   }
   
   @media (max-width: 480px) {
     .endofevent-title {
       font-size: 1.4rem;
     }
     .endofevent-message {
       font-size: 0.9rem;
     }
   }