/* LandingPage.css */

/* Variables de Couleurs */
:root {
  --background-color: #1e1e2c;
  --text-color: #ffffff;
  --secondary-text-color: #cccccc;
  --input-bg-color: #1e1e1e;
  --input-focus-bg: #2e2e2e;
  --input-hover-bg: #2a2a2a;
  --button-bg: #333333;
  --button-hover-bg: #ffffff;
  --button-hover-text: #333333;
  --link-hover-color: #64b5f6;
  --error-color: red;
  --border-color: #ddd;
  --focus-border-color: #1e88e5;
}

/* Container Principal */
.landing-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 0 20px;
}

/* Section Supérieure : Titre et Sous-Titre */
.top-section {
  text-align: center;
  margin-top: 10%;
}

.landing-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 7rem;
  margin: 0 0 20px 0;
  color: var(--text-color);
}

.landing-subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 2.5rem;
  margin: 0;
  color: var(--text-color);
}

/* Section Centrale : Description, Input et Bouton */
.middle-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  padding-top: 20px;
}

.landing-description {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  width: 80%;
  margin: 0 0 20px 0;
  color: var(--secondary-text-color);
  text-align: center;
}

.error-message {
  color: var(--error-color);
  font-size: 0.9rem;
  margin-bottom: 15px;
}

/* Container de l'Input */
.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Champ de Saisie */
.access-code-input {
  width: 250px;
  padding: 10px 15px;
  font-size: 1rem;
  text-align: center;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: var(--input-bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, border 0.3s ease;
}

.access-code-input:hover {
  background-color: var(--input-hover-bg);
}

.access-code-input:focus {
  background-color: var(--input-focus-bg);
  border-color: var(--focus-border-color);
  outline: none;
}

/* Bouton d'Accès */
.access-button {
  margin: 20px 0 10px 0;
  padding: 10px 25px;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--text-color);
  background-color: var(--button-bg);
  border: 2px solid var(--button-bg);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.access-button:hover {
  background-color: var(--button-hover-bg);
  color: var(--button-hover-text);
}

/* Liens */
.pasdecode,
.test-link {
  display: inline-block;
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s;
  margin-top: 20px;
}

.test-link {
  text-decoration: underline;
}

.test-link:hover {
  color: var(--link-hover-color);
}

/* Suppression des contours au focus */
.access-button:focus,
.test-link:focus {
  outline: none;
}
.landingnoauth-description {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  width: 80%;
  margin: 0 0 20px 0;
  color: var(--secondary-text-color);
  text-align: center;
}

/* Responsive Design */
@media (max-width: 600px) {
  .landing-page-container {
    padding: 0;
  }

  .landing-title {
    margin-top: 20%;
    font-size: 5.5rem;
    margin-bottom: 10px;
  }

  .landing-subtitle {
    font-size: 1.6rem;
    font-weight: 400;
  }

  .landing-description {
    width: 65%;
    margin: 0 0 20px 0;
  }

  .landingnoauth-description {
    width: 70%;
    font-size: 1rem;
  }

  .access-code-input {
    width: 30vh;
    margin-bottom: 0;
  }

  .access-button {
    padding: 10px 20px;
    font-size: 1rem;
    border-color: #6c6c6c;
    margin-bottom: 0;
  }

  .pasdecode {
    margin-top: 20px;
  }
}
