/* src/styles/EventCreationModal.css */

/* L'overlay couvre toute la fenêtre et crée un fond semi-transparent */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fond noir semi-transparent */
    backdrop-filter: blur(5px); /* Flou gaussien pour l'effet de flou */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000; /* Pour être devant le reste du contenu */
  }
  
  /* Le contenu de la modale */
  .modal-content {
    background-color: #1e1e1e; /* Fond sombre pour correspondre à la charte */
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px; /* Largeur maximale */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    color: #fff;
  }
  
  /* Style du titre */
  .modal-content h2 {
    margin-top: 0;
    margin-bottom: 15px;
    text-align: center;
  }
  
  /* Chaque groupe de champ (label + input/select) */
  .modal-form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  /* Style des labels */
  .modal-form-group label {
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  /* Style des champs de saisie */
  .modal-form-group input,
  .modal-form-group select {
    padding: 10px;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #2a2a2a;
    color: #fff;
  }
  
  /* Conteneur des boutons */
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  /* Style général pour les boutons dans la modale */
  .modal-buttons .btn {
    flex: 1;
    margin: 5px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
  }
  
  /* Bouton primaire (création) */
  .btn-primary {
    background-color: #1E88E5;
    color: #fff;
  }
  
  .btn-primary:hover {
    background-color: #1766ac;
  }
  
  /* Bouton secondaire (annulation) */
  .btn-secondary {
    background-color: #9E9E9E;
    color: #fff;
  }
  
  .btn-secondary:hover {
    background-color: #7e7e7e;
  }