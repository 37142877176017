/* spinner.css */

/* Positionne le conteneur au centre de l'écran */
.loading-container {
  display: flex;
  flex-direction: column; /* Aligne les éléments verticalement */
  align-items: center; /* Centre horizontalement */
  justify-content: center; /* Centre verticalement */
  color: #fff; /* Couleur du texte */
}

/* Style du texte */
.loading-text {
  margin-bottom: 20px; /* Espacement entre le texte et le spinner */
}

/* Style du spinner */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  display: block;
  border: 4px solid rgba(0, 0, 0, 0.1); /* Couleur légère pour les bords */
  border-top: 4px solid #c8c8c8; /* Couleur principale */
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  animation: spin 0.9s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
