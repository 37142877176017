/* /Users/launchmachine/PROGRAMMATION/Projets/Mixify/src/styles/WaitingPage.css */

.waiting-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #222;
  color: #fff;
  text-align: center;
  padding: 20px;
}

.logo-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.waiting-container .logo-container .logo-title {
  font-size: 4rem;
}

.waiting-container .logo-container .logo-subtitle {
  font-size: 1.5rem;
}

.waiting-title {
  font-size: 1.8rem;
}

.waiting-message {
  font-size: 1.2rem;
  max-width: 600px;
}

.loading-message {
  font-size: 1.5rem;
}

.countdown {
  padding: 15px 0;
}

.countdown strong {
  font-size: 3rem;
}

/* Styles pour les cards */
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 30px;
  width: 100%;
  max-width: 800px;
}

.card {
  background: rgb(225, 225, 225);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.card h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.card p {
  font-size: 1rem;
  margin: 5px 0;
}

/* Nouvelles règles pour intégrer les icônes */
.card-row {
  display: flex;
  align-items: flex-start;
}

.card-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  flex-shrink: 0;
  color: #333; /* Ajustez la couleur si besoin */
}