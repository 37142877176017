/* src/styles/Auth.css */

/* Variables de Couleurs pour une Gestion Facile */
:root {
  --color-background: #1b1b1b;
  --color-input-bg: #2f2f44;
  --color-input-focus: #3f3f55;
  --color-text: #ffffff;
  --color-secondary-text: #cccccc;
  --color-error: #ff4b4b;
  --color-error-bg: #ffe5e5;
  --color-success: #4caf50;
  --color-success-bg: #e5ffe5;
  --color-button: #149bd0;
  --color-button-hover: #09729b;
  --color-link: #1E88E5;
  --color-link-hover: #1a72c0;
  --color-border: #444;
}

.switch-auth-links {
  display: flex;
  margin-top: 20px;
  justify-items: center;
  text-align: center;
}

.switch-auth-links p {
  margin: 5px 0;
  color: #fff;
  font-size: 15px;
}

.switch-auth-links a {
  color: #1d8beb;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.switch-auth-links a:hover {
  color: #166bb3;
  text-decoration: underline;
}
.auth-page {
  margin-top: 120px;
}

.auth-container {
  max-width: 400px;
  margin: 0px auto;
  margin-top: 150px;
  margin-bottom: 100px;
  background-color: var(--color-background);
  padding: 20px;
  border-radius: 8px;
  color: var(--color-text);
  font-family: 'Poppins', sans-serif;
}

.auth-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.auth-container form {
  display: flex;
  flex-direction: column;
}

.auth-container label {
  margin-bottom: 5px;
  font-weight: 500;
}

.auth-container input,
.auth-container select {
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-input-bg);
  color: var(--color-text);
}

.auth-container input:focus,
.auth-container select:focus {
  background-color: var(--color-input-focus);
  outline: none;
  border: 1px solid var(--color-link);
}

.auth-container button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-button);
  color: var(--color-text);
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-container button:hover {
  background-color: var(--color-button-hover);
}

.auth-container button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.auth-message {
  text-align: center;
  margin-top: 10px;
  font-size: 0.9rem;
}

.auth-message.error {
  color: var(--color-error);
  background-color: var(--color-error-bg);
  padding: 10px;
  border-radius: 4px;
}

.auth-message.success {
  color: var(--color-success);
  background-color: var(--color-success-bg);
  padding: 10px;
  border-radius: 4px;
}

.switch-auth-link {
  text-align: center;
  margin-top: 15px;
}

.switch-auth-link a {
  color: var(--color-link);
  text-decoration: none;
  font-weight: 500;
}

.switch-auth-link a:hover {
  text-decoration: underline;
}

/* Checkbox Container */
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.checkbox-container input {
  margin-right: 8px;
}

/* Password Toggle Icon */
.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: var(--color-secondary-text);
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}
.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
  color: #ccc;
  font-weight: 500;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 2px solid #cccccc5c;
}

.separator:not(:empty)::before {
  margin-right: 10px;
}

.separator:not(:empty)::after {
  margin-left: 10px;
}

.password-toggle-icon:hover {
  color: var(--color-text);
}

.forgot-password {
  color: #149bd0;
}

.switch-auth-link {
  text-align: center;
  margin-top: 15px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .auth-container {
    padding: 15px;
    margin: 20px auto;
  }

  .auth-container h2 {
    font-size: 1.5rem;
  }

  .password-toggle-icon {
    font-size: 1rem;
  }

  .password-criteria {
    padding: 8px;
  }

  .password-criteria li {
    font-size: 0.85rem;
  }
}