/* AuthMenu.css */

/* Style du bouton "Se connecter" quand l'utilisateur n'est pas connecté */
.auth-menu-login-btn {
    background-color: #1E88E5; /* Même couleur que home-login-btn */
    color: #ffffff;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    margin-left: 10px; /* petit décalage si besoin */
  }
  
  .auth-menu-login-btn:hover {
    background-color: #1565c0;
  }

  /* Squelette global : on affiche en ligne, aligné au centre */
.auth-menu-skeleton {
    display: inline-flex;
    align-items: center;
    gap: 10px; /* espace entre les deux placeholders */
  }
  
  /* Cercle squelette */
  .skeleton-circle {
    width: 40px; 
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1); /* Couleur translucide */
    /* Animation type “pulse” ou “shimmer” possible */
    animation: skeleton-pulse 1.5s ease-in-out infinite;
  }
  
  /* Rectangle squelette (simulateur du bouton “Se connecter”) */
  .skeleton-button {
    width: 100px; /* Largeur approx du vrai bouton */
    height: 35px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.1);
    animation: skeleton-pulse 1.5s ease-in-out infinite;
  }
  
  /* Lien de connexion : inchangé, juste un rappel */
  .auth-menu-login-btn {
    background-color: #1E88E5;
    color: #fff;
    padding: 6px 12px;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .auth-menu-login-btn:hover {
    background-color: #1565c0;
  }
  
  .auth-menu-text {
    display: inline-block; 
  }
  
  .auth-menu-icon {
    display: none;
    margin-left: 6px;  /* petit espacement, si vous voulez */
    font-size: 1rem;   /* ajustez selon vos besoins */
    /* color: #fff; etc. */
  }

  /* Animation “pulse” simple (pour simuler un effet de chargement) */
  @keyframes skeleton-pulse {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.4;
    }
  }

  @media (max-width: 768px) {
    .auth-menu-text {
      display: none;
    }
    .auth-menu-login-btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    .auth-menu-icon {
      display: inline-block;
      margin-left: 0;
    }
  }