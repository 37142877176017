/* src/styles/SignupPage.css */

/* Réutiliser les styles de Auth.css */
@import "./Auth.css";

/* Conteneur des Champs */
.field-container {
  position: relative; /* Pour positionner l'icône de masquage */
  margin-bottom: 15px;
}

/* Checkbox Container */
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

/* Ajustements pour Password Criteria */
.password-criteria {
  background-color: #2f2f44;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.password-criteria p {
  margin: 0 0 5px 0;
  font-weight: 500;
}

.password-criteria ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.password-criteria li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.password-criteria li::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 50%;
}

.password-criteria li.met::before {
  background-color: #1e88e5; /* Vert */
}

.password-criteria li.unmet::before {
  background-color: #ff4b4b; /* Rouge */
}

/* Icône de Masquage/Affichage du Mot de Passe */
.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: var(--color-secondary-text);
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.password-toggle-icon:hover {
  color: var(--color-text);
}

/* Bouton de Soumission */
.auth-container button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: #1e88e5;
  color: var(--color-text);
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.auth-container label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
}
.password-input-container {
  position: relative;
  margin-bottom: 15px;
}

.password-input-container input:focus {
  background-color: var(--color-input-focus);
  border: 1px solid var(--color-link);
  outline: none;
}

.auth-container button:hover {
  background-color: var(--color-button-hover);
}

.auth-container button:disabled {
  background-color: #555;
  cursor: not-allowed;
}
.privacy-policy-link {
  color: #1e88e5;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.privacy-policy-link:hover {
  color: var(--color-link-hover);
}

.auth-message {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 0.9rem;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.auth-message.error {
  color: var(--color-error);
  background-color: var(--color-error-bg);
  opacity: 1;
}

.auth-message.success {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* pour le mettre au-dessus des autres éléments */
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* arrière-plan semi-transparent */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: var(--color-success);

  opacity: 1;
}
.checkbox-wrapper {
  width: 100%;
  margin-bottom: 15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

/* Responsive Design */
@media (max-width: 600px) {
  .auth-container {
    padding: 15px;
    margin: 20px auto;
  }

  .auth-container h2 {
    font-size: 1.5rem;
  }

  .password-toggle-icon {
    font-size: 1rem;
  }

  .password-criteria {
    padding: 8px;
  }

  .password-criteria li {
    font-size: 0.85rem;
  }
}
