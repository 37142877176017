:root {
  --accepted-title-max-width: 90%;
}

/* =========================================================================
   1. Scrollbars (chromium/webkit, firefox)
   ========================================================================= */
/* Pour tous les navigateurs prenant en charge Webkit (Chrome, Edge, Safari) */
*::-webkit-scrollbar {
  width: 10px;  /* Largeur (scroll vertical) */
  height: 10px; /* Hauteur (scroll horizontal) */
}
*::-webkit-scrollbar-track {
  background: #1e1e1e;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background: #444; 
  border-radius: 10px;
  border: 2px solid #1e1e1e; 
}
*::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Pour Firefox */
* {
  scrollbar-width: thin; 
  scrollbar-color: #444 #1e1e1e; 
}

/* Scrollbar spécifiques aux barres refused/played */
.refused-bar::-webkit-scrollbar,
.played-bar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.refused-bar::-webkit-scrollbar-thumb,
.played-bar::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}
.refused-bar::-webkit-scrollbar-thumb:hover,
.played-bar::-webkit-scrollbar-thumb:hover {
  background: #777;
}

/* =========================================================================
   2. Resets / Global
   ========================================================================= */
body, html {
  overflow-x: hidden; 
}

* {
  box-sizing: border-box; 
}

/* =========================================================================
   3. Page principale : Dashboard
   ========================================================================= */
.dashboard-page {
  background-color: #121212;
  color: #ffffff;
  min-height: 100vh;
  padding: 20px;
}

.dashboard-row {
  margin-top: 0px;
}

/* Colonne gauche */
.dashboard-left-col {
  padding: 10px;
}

/* Colonne droite */
.dashboard-right-col {
  padding: 10px;
}

/* =========================================================================
   4. Cartes de morceaux (dashboard-card)
   ========================================================================= */
.dashboard-card {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
  background-color: #1e1e1e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-card.dragging {
  box-shadow: 0 5px 20px rgba(0,0,0,0.3);
  background-color: #2f2f2f;
  cursor: grabbing;
  opacity: 0.9;
}

.dashboard-card.dropping {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 1;
}

/* Lorsqu’une card est "accepted", on ajuste la disposition */
.dashboard-card.accepted {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px; 
}

/* Haut de la card (titre/artiste + boutons) en ligne */
.dashboard-card.accepted .dashboard-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Titre + Artiste stackés en colonne */
.dashboard-card.accepted .dashboard-title-artist-container {
  display: flex;
  flex-direction: column;
  gap: 0;
  flex: 1;
  min-width: 0;
}

/* Autoriser la troncature (ellipsis) via CSS sur "accepted" */
.dashboard-card.accepted .dashboard-title,
.dashboard-card.accepted .dashboard-artist {
  display: inline-block;
  max-width: var(--accepted-title-max-width);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Bloc général du contenu interne d’une carte */
.dashboard-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  min-width: 0;
}

/* =========================================================================
   5. Titres, artistes, liens et autres styles communs
   ========================================================================= */
.dashboard-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Poppins", sans-serif;
  max-width: 90%;
  color: #ffffff;
  white-space: normal;
  word-wrap: break-word;
  overflow: visible;
}

.dashboard-title:hover {
  text-decoration: none; 
}

.dashboard-artist {
  font-size: 0.8rem;
  font-style: italic;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  white-space: normal;
  word-wrap: break-word;
  overflow: visible;
}

.dashboard-artist:hover {
  text-decoration: none; 
}

.back-to-events {
  display: inline-block;
  font-size: 0.8rem;
  color: #ccc;
  margin-bottom: 5px;
  text-decoration: none;
}
.back-to-events:hover {
  color: #fff;
  font-weight: 600;
  text-decoration: solid;
}

/* =========================================================================
   6. Actions (boutons, barres d'options)
   ========================================================================= */
.dashboard-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px; 
  margin-top: 0px;
}

/* Titre de section */
.dashboard-section-title {
  font-size: 1.3rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-left: 5px;
  margin-bottom: 20px;
  color: #ffffff;
}

/* Statut si aucune suggestion */
.dashboard-section-status {
  margin-left: 5px;
}

/* Boutons communs DJ */
.btn-common {
  min-width: 110px;
  height: 40px;
  padding: 8px 12px;
  font-size: 0.9rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Bouton Outline Danger */
.btn-outline-danger {
  font-weight: 600;
}
.btn-outline-danger .btn-reset-session {
  margin-top: 0px;
  font-weight: bold;
  text-align: center;
  padding: 10px 10px;
  width: 50%;
  max-width: 250px;
  background-color: #cc0000;
  color: #fff;
  border: 3px;
  border-radius: 5px;
  border-color: #cc0000;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.btn-reset-session:hover {
  background-color: #5f0000;
}

/* =========================================================================
   7. Barres "played" et "refused"
   ========================================================================= */
.played-bar,
.refused-bar {
  position: fixed;
  bottom: 0;
  background-color: #1e1e1e;
  color: #fff;
  text-align: center;
  padding: 0px;
  transition: height 0.3s ease;
  box-sizing: border-box;
  height: 30px; 
  overflow-y: hidden;
}

.played-bar {
  right: 0;
  width: 50%;
}
.played-bar.open {
  height: 200px;
  overflow-y: auto;
}

.refused-bar {
  left: 0;
  width: 50%;
  background-color: #2e2e2e; 
  overflow-y: auto;
}
.refused-bar.open {
  height: 200px;
  overflow-y: auto;
}

/* Conteneur de la liste dans refused-bar */
.refused-tracks-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.refused-track-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}
.refused-track-time {
  flex: 0 0 100px;
  text-align: left;
  font-size: 0.9rem;
  color: #ccc;
}
.refused-track-details {
  flex: 1;
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.refused-track-details strong {
  display: block;
  font-size: 1rem;
  color: #ffffff;
}
.refused-track-title-artist {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.refused-track-artist {
  font-size: 0.9rem;
  color: #ccc;
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40%;
}
.refused-track-title {
  font-size: 0.9rem;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
  flex: 1;
}
.refused-track-reason {
  font-size: 0.8rem;
  color: #ff6666;
  margin-top: 5px;
  text-align: left;
}
.refused-track-actions {
  flex: 0 0 100px;
  text-align: right;
}



/* =========================================================================
   9. Copy-Message (tooltip)
   ========================================================================= */
.copy-message {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  animation: fade-in-out 2s ease;
  z-index: 20000;
}
@keyframes fade-in-out {
  0%   { opacity: 0; }
  20%  { opacity: 1; }
  80%  { opacity: 1; }
  100% { opacity: 0; }
}

/* =========================================================================
   10. Zone de boutons (dashboard-buttons)
   ========================================================================= */
.dashboard-buttons {
  position: relative;
  bottom: 0;
  left: 0;
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
}
.dashboard-buttons a {
  background-color: #333;
  color: #fff;
}

/* =========================================================================
   11. Champs, toggles & forms
   ========================================================================= */
.settings-label {
  display: flex;
  gap: 5px;
  font-size: 0.9rem;
  color: #ccc;
  margin-bottom: 10px;
}
.settings-input,
.settings-textarea {
  width: 100%;
  padding: 10px;
  background-color: #444;
  border: 1px solid #666;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
}
.settings-textarea {
  min-height: 100px;
}

/* =========================================================================
   12. Checkbox UI (hCheckbox)
   ========================================================================= */
/* Source Uiverse.io by Galahhad */
.ui-checkbox {
  --primary-color: #1E88E5;
  --secondary-color: #fff;
  --primary-hover-color: #1E88E5;
  --checkbox-diameter: 20px;
  --checkbox-border-radius: 5px;
  --checkbox-border-color: #d9d9d9;
  --checkbox-border-width: 1px;
  --checkbox-border-style: solid;
  --checkmark-size: 1.2;
  
  width: var(--checkbox-diameter);
  height: var(--checkbox-diameter);
  border-radius: var(--checkbox-border-radius);
  background: var(--secondary-color);
  border: var(--checkbox-border-width) var(--checkbox-border-style) var(--checkbox-border-color);
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
  appearance: none;
}
.ui-checkbox::after {
  content: "";
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
  border-radius: inherit;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ui-checkbox::before {
  content: "";
  position: absolute;
  top: 40%; left: 50%;
  width: 4px;
  height: 7px;
  border-right: 2px solid var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity 0.1s;
}
.ui-checkbox:hover {
  border-color: var(--primary-color);
}
.ui-checkbox:checked {
  background: var(--primary-color);
  border-color: transparent;
}
.ui-checkbox:checked::before {
  opacity: 1;
  transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}
.ui-checkbox:active:not(:checked)::after {
  transition: none;
  box-shadow: none;
  opacity: 1;
}

/* =========================================================================
   13. Motifs de refus (refusal-reason)
   ========================================================================= */
.refusal-reason-card {
  background-color: #1e1e1e;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all 0.1s ease;
}
.refusal-reason-card:hover {
  background-color: #282828;
}
.refusal-reason-title {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
}
.refusal-reason-text {
  font-size: 0.9rem;
  color: #cccccc;
}
.refusal-reason-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}
.refusal-reason-input,
.refusal-reason-textarea {
  width: 100%;
  padding: 10px;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 4px;
  color: #fff;
  font-size: 0.9rem;
}
.refusal-reason-textarea {
  min-height: 60px;
  resize: vertical;
}
.refusal-reason-button {
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
}
.refusal-reason-button.add {
  width: 100%;
  background-color: #005700;
  color: #fff;
  border-radius: 8px;
  padding: 12px;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 10px;
}
.refusal-reason-button.add:hover {
  background-color: #009900;
}
.refusal-reason-button.delete {
  background-color: #aa0000;
  color: #fff;
}
.refusal-reason-button.delete:hover {
  background-color: #ff0000;
}
.refusal-popover {
  top: 40px;
  right: 0;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 5px;
  padding: 10px;
  z-index: 9999;
  width: 150px;
}
.refusal-reason-input.default {
  background-color: #2a2a2a;
  color: #bbb;
  border: 1px solid #444;
  font-weight: 600;
  cursor: not-allowed;
}

/* =========================================================================
   14. Badges & Status
   ========================================================================= */
.djplus-badge {
  font-weight: 600;
  background: rgb(255, 165, 21);
  color: #1b1b1b;
  padding: 5px 10px;
  transform: translate(5%, -20%);
  box-shadow: 0 2px 8px rgba(0,0,0,0.3);
  z-index: 10;
  border-radius: 3px;
}

.event-status-display {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.event-status-bullet {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 6px;
  margin-left: 10px;
  display: inline-block;
}
.event-status-text {
  font-size: 0.9rem;
  font-weight: 600;
  color: #fff;
}

/* =========================================================================
   15. Responsive / Media Queries
   ========================================================================= */
@media (max-width: 768px) {

  /* 1) Ajustements généraux (layout mobile) */
  .dashboard-page {
    padding: 20px;
  }
  .dashboard-row {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .dashboard-left-col,
  .dashboard-right-col {
    width: 100%;
    padding: 0;
  }

  /* 2) Cartes en colonne pour le mobile */
  .dashboard-card {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  /* Titre de section un peu plus petit */
  .dashboard-section-title {
    font-size: 1.2rem;
  }

  /* 3) Boutons communs */
  .btn-common {
    min-width: 90px;
    height: 32px;
    padding: 4px 8px;
    font-size: 0.8rem;
  }

  /* 4) Titre/Artiste : plus adaptables */
  .dashboard-title,
  .dashboard-artist {
    font-size: 1rem;
    max-width: 100%;
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
    word-wrap: break-word;
  }

  /* 5) Actions en colonne */
  .dashboard-actions {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
  .dashboard-actions .btn {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  /* 6) Barre de boutons en bas de l’écran (dashboard-buttons) */
  .dashboard-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #121212;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;

    z-index: 1000;
  }
  .dashboard-buttons a {
    text-decoration: none;
    color: #fff;
    padding: 10px 15px;
    background-color: #1e1e1e;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;

  }
  .dashboard-buttons a:hover {
    background-color: #333;
  }

  /* 7) Ajustements de la modale "settings" */
  .modal-settings-content {
    width: 90%;
    max-width: 600px;
    padding: 15px;
    max-height: 90vh;
    overflow-y: auto;
  }
  .settings-container {
    flex-direction: column;
    height: auto;
  }

  /* Onglets en ligne, scroll horizontal si trop d’onglets */
  .settings-tabs-left {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #444;
    flex-direction: row;
    overflow-x: auto;
  }
  .settings-tab {
    flex: 1 1 auto;
    text-align: center;
    border-bottom: none;
    border-right: 1px solid #444;
  }
  .settings-tab:last-child {
    border-right: none;
  }
  .settings-tabs-right {
    width: 100%;
    padding: 15px;
    overflow-y: auto;
  }

  .modal-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
  }

  /* 8) Modale de réinit sur mobile */
  .modal-reinit-text {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  .modal-reinit {
    background-color: rgba(0,0,0,0.9);
  }
  .modal-reinit-content-buttons {
    margin-bottom: 10px;
  }

  /* 9) Bouton reset session */
  .btn-reset-session {
    width: 100%;
    max-width: none;
  }

  /* 10) Barre refused / played pleine largeur */
  .refused-bar:not(.open),
  .played-bar:not(.open) {
    height: 30px;
    overflow-y: hidden;
  }
  .refused-bar.open,
  .played-bar.open {
    height: 200px;
    overflow-y: auto;
  }
  .refused-bar,
  .played-bar {
    width: 100% !important;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999 !important;
  }

  /* 11) GenreSelector adapté pour mobile */
  .track-genres {
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .dashboard-content {
    flex-direction: column;
    gap: 5px;
  }
  .dashboard-card.accepted .dashboard-title,
  .dashboard-card.accepted .dashboard-artist {
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
    max-width: 100%;
    word-wrap: break-word;
  }
  .dashboard-card.accepted {
    gap: 0px;
  }
}