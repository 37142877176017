/* =========================================================================
   EvenementsManager.css
   ========================================================================= */

/* =========================================================================
   1. Mise en page générale
   ========================================================================= */
   .events-manager-page {
    background-color: #121212;
    color: #ffffff;
    min-height: 100vh;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
  /* =========================================================================
     2. Titre de la page
     ========================================================================= */
  .events-manager-title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin-top: 90px;
    margin-bottom: 30px;
    color: #ffffff;
  }
  
  /* =========================================================================
     3. Section de création d'événement
     ========================================================================= */
  .events-manager-create {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    gap: 10px;
  }
  
  .events-manager-input {
    width: 300px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #1e1e1e;
    color: #fff;
    font-size: 1rem;
  }
  
  .events-manager-input::placeholder {
    color: #888;
  }
  
  .btn-primary {
    background-color: #1E88E5;
    color: #FFFFFF;
    font-weight: 700;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #1766ac;
  }
  
  /* =========================================================================
     4. Liste des événements
     ========================================================================= */
  .events-manager-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* =========================================================================
     5. Carte d'événement
     ========================================================================= */
  .events-manager-card {
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .events-manager-card:hover {
    background-color: #2a2a2a;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.7);
  }
  
  /* Contenu de la carte */
  .events-manager-card-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  /* =========================================================================
     6. Nom de l'événement et édition
     ========================================================================= */
  .events-manager-event-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: #ffffff;
  }
  
  /* Conteneur du nom de l'événement avec icône d'édition */
  .events-manager-event-name-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  /* Assurer que les éléments enfants sont bien centrés */
  .events-manager-event-name-container h3,
  .events-manager-event-name-container input {
    margin: 0;
    display: flex;
    align-items: center;
  }
  
  /* Icônes d'édition */
  .edit-icon {
    cursor: pointer;
    color: #ffffff;
    margin-bottom: 2px;
    transition: color 0.3s ease;
    font-size: 1.2rem; /* taille des icônes */
  }
  
  .edit-icon:hover {
    color: #1E88E5;
  }
  
  /* Icônes de confirmation / annulation */
  .confirm-icon {
    color: #4CAF50;              /* Vert pour la confirmation */
    font-size: 1.7rem;           /* Taille augmentée */
    margin-right: 8px;
    background-color: rgba(76, 175, 80, 0.1);
    border-radius: 4px;
    padding: 4px;
  }
  
  .confirm-icon:hover {
    color: #357a38;
    background-color: rgba(76, 175, 80, 0.2);
  }
  
  .cancel-icon {
    color: #dc3545;              /* Rouge pour l'annulation */
    font-size: 1.7rem;           
    background-color: rgba(220, 53, 69, 0.1);
    border-radius: 4px;
    padding: 4px;
  }
  
  .cancel-icon:hover {
    color: #c82333;
    background-color: rgba(220, 53, 69, 0.2);
  }
  
  /* Groupe d'icônes d'édition (valider / annuler) */
  .events-manager-edit-icons {
    display: flex;
    align-items: center;
    gap: 5px; 
  }
  
  /* Champ d'édition inline */
  .events-manager-edit-input {
    width: 400px;
    padding: 8px 10px;
    border: 1px solid #1E88E5;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #2a2a2a;
    color: #ffffff;
  }
  
  .events-manager-edit-input:focus {
    outline: none;
    border-color: #1766ac;
  }
  
  .events-manager-event-id {
    font-size: 0.9rem;
    color: #cccccc;
  }
  
  /* =========================================================================
     7. Actions sur la carte
     ========================================================================= */
  .events-manager-actions {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  /* Boutons généraux */
  .events-manager-actions .btn {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .btn-info {
    background-color: #2196F3;
  }
  
  .btn-info:hover {
    background-color: #1766ac;
  }
  
  .btn-success {
    background-color: #4CAF50;
  }
  
  .btn-success:hover {
    background-color: #357a38;
  }
  
  .btn-warning {
    background-color: #3f3f3f;
    color: #000;
  }
  
  .btn-warning:hover {
    background-color: #363636;
  }
  
  .btn-secondary {
    background-color: #9E9E9E;
  }
  
  .btn-secondary:hover {
    background-color: #7e7e7e;
  }
  
  /* Statut lorsque la liste est vide */
  .events-manager-status {
    text-align: center;
    font-size: 1rem;
    color: #888;
  }
  
  /* Bouton de suppression */
  .btn-danger {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #dc3545;
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .btn-danger:hover {
    background-color: #c82333;
  }
  
  /* Bouton "QR Code" */
  .btn-qr-code {
    background-color: #2196F3; 
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s ease;
  }
  
  .btn-qr-code:hover {
    background-color: #1976D2;
  }
  
  .no-margin {
    margin: 0;
  }
  
  /* Exemple d'input container */
  .example-input-container {
    max-width: 500px;
    margin-bottom: 1.5rem;
    margin-left: 0;
    border: 1px solid #444;
    border-radius: 5px;
    padding: 10px;
    background-color: #1e1e1e;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .example-input {
    background-color: #2a2a2a;
    border: 1px solid #1E88E5;
    border-radius: 4px;
    padding: 8px 10px;
    color: #fff;
    flex: 1;
  }
  
  /* =========================================================================
     8. Bouton d'aide (fixe, en bas à droite)
     ========================================================================= */
  .help-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1100;
    border-radius: 50%;
    backdrop-filter: blur(3px);
  }
  
  /* =========================================================================
     9. Modale d'aide : overlay et contenu
     ========================================================================= */
  .help-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1b1b1b75;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500000;
  }
  
  .help-modal-content {
    background: #1b1b1b;
    position: relative;
    padding: 20px;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: none;
    height: 90%;
    border-radius: 8px;
    overflow-y: auto;
  }
  
  .help-modal-close {
    position: fixed;
    top: 20px;
    right: 25px;
    background: transparent;
    border: none;
    font-size: 3rem;
    color: #fff;
    cursor: pointer;
    z-index: 10000;
  }
  
  /* Scrollbar personnalisée pour la modale d'aide */
  .help-modal-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .help-modal-content::-webkit-scrollbar-track {
    background: #1b1b1b;
  }
  
  .help-modal-content::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 10px;
    border: 2px solid #1b1b1b;
  }
  
  /* Empêcher le scroll du body quand la modale est ouverte */
  body.modal-open {
    overflow: hidden;
  }
  
  /* =========================================
   MODALE GÉNÉRIQUE (overlay + contenu)
========================================= */
.modal-overlay {
  position: fixed;            /* On recouvre tout l'écran */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6); /* Fonce légèrement l'arrière-plan */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;             /* Au-dessus du reste */
}

.modal-content {
  background-color: #1b1b1b;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;           /* Largeur max: 600px */
  max-height: 80vh;           /* Hauteur max: 80% de la viewport */
  overflow-y: auto;           /* Active le scroll vertical si besoin */
}

/* On peut personnaliser la scrollbar interne si on veut */
.modal-content::-webkit-scrollbar {
  width: 8px;
}
.modal-content::-webkit-scrollbar-thumb {
  background: #444; 
  border-radius: 4px;
}


  /* =========================================================================
     10. Copie du lien d'accès
     ========================================================================= */
  .events-manager-access-link {
    cursor: pointer;
    font-size: 0.9rem;
    color: #1E88E5;
    text-decoration: underline;
    margin-top: 5px; 
  }
  
  .copy-message {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    white-space: nowrap;
    animation: fade-in-out 2s ease;
    z-index: 20000;
  }
  
  @keyframes fade-in-out {
    0%   { opacity: 0; }
    20%  { opacity: 1; }
    80%  { opacity: 1; }
    100% { opacity: 0; }
  }
  
  /* =========================================================================
     11. Bouton "Accéder à Search"
     ========================================================================= */
  .btn-access {
    background-color: #2196F3;
    color: #fff;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 200px; 
    height: 35px;
    margin-top: 5px;
    text-align: center;
  }
  
  .btn-access:hover {
    background-color: #1766ac;
    transform: scale(1.02);
  }
  
  /* =========================================================================
     12. Indicateur de statut
     ========================================================================= */
  .event-status-display {
    display: flex;
    align-items: center;
  }
  
  .event-status-bullet {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 6px;
    margin-left: 20px;
    display: inline-block;
  }
  
  .event-status-text {
    font-size: 0.9rem;
    font-weight: 600;
    color: #fff;
  }
  
  /* Ligne du haut : nom et statut */
  .events-manager-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Badge "DJplus" */
  .djplus-badge {
    font-weight: 600;
    background: rgb(255, 165, 21);
    color: #1b1b1b;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 0.9rem;
    margin-top: 8px;
    text-align: center;
  }
  
  /* Menu contextuel (3 points) */
  .menu-item {
    background: transparent;
    color: white;
    width: 100%;
    padding: 10px;
    text-align: left;
    border: none;
    cursor: pointer;
    font: inherit; /* garde la police du parent */
  }
  
  .menu-item:hover {
    background-color: #21262C;
  }
  
  /* =========================================================================
     13. Responsive / Media Queries
     ========================================================================= */
  @media (max-width: 768px) {
  
    .events-manager-page {
      padding: 10px;
    }
  
    .events-manager-title {
      font-size: 1.5rem;
      margin-top: 120px; /* Ajustement à l'écran plus petit */
      margin-bottom: 20px;
    }
  
    .events-manager-create {
      flex-direction: column;
      gap: 15px;
    }
  
    .events-manager-input {
      width: 100%;
      font-size: 1rem;
    }
  
    .events-manager-edit-input {
      width: 200px;
    }
  
    .events-manager-list {
      gap: 15px;
    }
  
    .events-manager-actions {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .events-manager-card {
      flex-direction: column;
      gap: 15px;
      padding: 15px;
    }
  
    .events-manager-card:hover {
      background-color: none;
      box-shadow: none;
    }
  
    .events-manager-actions .btn {
      padding: 8px;
      font-size: 0.9rem;
    }
  
    .events-manager-status {
      font-size: 1rem;
    }
  
    .help-button {
      bottom: 25px;
      right: 20px;
    }
  
    .help-modal-content {
      position: relative;
      width: 95%;
      max-width: 700px;
      padding: 15px;
      border-radius: 4px;
    }
  
    .help-modal-close {
      position: absolute;
      top: 20px;
      right: 25px;
      background: transparent;
      border: none;
      font-size: 3rem;
      color: #fff;
      cursor: pointer;
      z-index: 10000;
    }
  
    .help-modal-content h2 {
      font-size: 1.5rem;
    }
  
    .help-modal-content p,
    .help-modal-content li {
      font-size: 1rem;
      line-height: 1.4;
    }
  
    .example-input-container {
      max-width: 100%;
    }
  
    .example-input {
      font-size: 0.9rem;
    }
  }