@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.qr-code-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  display: flex;
  z-index: 999000;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* évite que le contenu déborde de l’écran */
}

/* Conteneur principal de la modale */
.qr-code-modal-content {
  position: relative;  
  background-color: #F2FAFF;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  /* Par défaut, on prend tout l’espace sur mobile */
  width: 100%;
  height: 100%;
  border-radius: 0; 
  overflow: auto;
}

/* Sur desktop (>= 768px), on adopte une largeur/hauteur plus grande mais pas forcément plein écran */
@media (min-width: 768px) {
  .qr-code-modal-content {
    flex-direction: row;   /* Aperçu d’un côté, customization de l’autre */
    width: 80vw;          /* Ajuster selon besoin */
    max-width: 1000px;
    height: 80vh;         /* Ajuster selon besoin */
    border-radius: 12px;
  }

}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Bouton de fermeture */
.qr-code-modal-close {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

/* 
  Conteneur qui affiche l’aperçu complet (titre/logo/descriptif + QR code).
  Sur mobile, il est en haut (car flex-direction: column).
  Sur desktop, il peut être à gauche, et la personnalisation à droite (ou inverse).
*/
.qr-code-preview-container {
  flex: 1; /* prend l’espace disponible */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* 
  Zone de customisation (les options + bouton Télécharger).
  Sur mobile, ce bloc est en bas (flex-column).
  Sur desktop, on lui donne une largeur fixe pour qu’il soit à droite.
*/
.qr-code-customization-area {
  width: 100%;
  padding: 20px;
  overflow-y: auto; /* si trop d’options, scroll dans cette zone */
  box-sizing: border-box;
  background-color: #ffffff; /* petit contraste */
}

.djplus-badge {
  font-weight: 600;
  background: rgb(255, 165, 21);
  color: #1b1b1b;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 0.8rem;
}

@media (min-width: 768px) {
  .qr-code-customization-area {
    flex: 0 0 320px;  /* bloc à droite de 320px de large */
    border-left: 1px solid #ccc;
  }
}

/* 
  L’élément à capturer (previewRef). 
  Il doit être un carré et contenir tout le contenu sans déborder.
*/
.qr-code-preview {
  width: 100%;
  max-width: 600px;         /* large maximum sur desktop */
  aspect-ratio: 1 / 1;      /* carré */
  background-color: inherit; /* utiliser la couleur de fond dynamique */
  border-radius: 8px;
    border: 1px solid #ccc;
  
  /* Pour empêcher le contenu de sortir du carré : */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px;
  overflow: hidden; 
}

/* 
  Pour que les textes ne sortent pas, on autorise le retour à la ligne 
  et la césure si besoin.
*/
.qr-code-preview * {
  word-wrap: break-word;
  text-align: center;
}

/* Titre Événement */
.qr-code-event-name h2 {
  margin: 0;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 50px;
  white-space: nowrap;
display: inline-block; /* nécessaire pour text-overflow en inline */

}

/* Titre custom */
.qr-code-custom-title h2 {
  margin: 0;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 50px;
}

/* Logo Mixify */
.qr-code-logo img {
  max-width: 100px;
  margin-bottom: 10px;
}
.qr-code-modal-content .logo-title {
    font-size: 3.5rem; /* ou davantage */
  }

  .qr-code-modal-content  .logo-subtitle {
    font-size: 1.2rem;
  }

/* Description */
.qr-code-modal-instruction {
    margin: 10px 0 15px;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.4;
  
    /* Empêche le passage à la ligne et ajoute ... si trop long */
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block; /* nécessaire pour text-overflow en inline */
    max-width: 90%;       /* pour que l'ellipsis puisse agir */
    /* vous pouvez ajuster à 95% ou 100% selon votre rendu visuel */
  }

/* Zone du QR code lui-même */
.QRCode-img {
  margin-top: 0px;
}

/* Personnalisation */
.qr-code-customization-area h3 {
  margin-top: 0;
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 15px;
}

.customization-control {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.customization-control label {
  flex: 1;
  font-size: 1rem;
  color: #333;
}

.customization-control input[type="color"],
.customization-control input[type="checkbox"],
.customization-control input[type="text"] {
  flex: 1;
  padding: 5px;
  font-size: 1rem;
}
.qr-code-customization-area .customization-control input[type="text"]{
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  

.title-options {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 10px;
}
.title-options label {
  display: flex;
  align-items: center;
}
.title-options input[type="radio"] {
  margin-right: 8px;
}
.customization-control-desktop-stack {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
/* Bouton de téléchargement */
.btn-download-qr {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
}
.btn-download-qr:hover {
  background-color: #1976D2;
}

.qr-code-modal-infos {
    margin-top: 25px;
  }
.qr-code-modal-infos-spacer {
    margin-top: 40px; 
    height: 0; /* ou par exemple 20px, selon l'effet souhaité */
  }
/* ---------------------------
   1) MEDIA QUERY pour mobile
   --------------------------- */
   @media (max-width: 767px) {
    /* Réduire la taille maximum du carré */
    .qr-code-preview {
      max-width: 350px; /* ou 320px, à ajuster selon vos besoins */
      padding: 0px;     /* éventuellement moins de padding */
    }
  
    /* Réduire la taille du titre (Nom d'événement ou custom) */
    .qr-code-event-name h2,
    .qr-code-custom-title h2 {
      font-size: 1.4rem;   /* au lieu de 1.8rem */
      margin-top: 10px;
      margin-bottom: 25px; /* réduire la marge */
    }
  
    /* Réduire encore la taille du logo si besoin */
    .qr-code-modal-content .logo-title {
      font-size: 2.5rem; /* au lieu de 3.5rem */
      margin-top: 10px;
    }
    .qr-code-modal-content .logo-subtitle {
      font-size: 0.9rem;   /* au lieu de 1.2rem */
    }
  
    /* Réduire la taille de la description */
    .qr-code-modal-instruction {
      font-size: 1rem; /* au lieu de 1.6rem */
      margin: 0px 0 10px;
    }

    .customization-control-desktop-stack {
        flex-direction: row;  /* Si vous préférez l'alignement horizontal sur mobile */
        align-items: center;
      }
  
    /* Vous pouvez aussi réduire la taille du QR code, si nécessaire */
    .QRCode-img svg {
      width: 150px !important; 
      height: auto !important;
      /* 
        Ici, on force le <QRCodeSVG> à 200px de large. 
        On peut conserver un ratio correct si "height: auto".
        => Ajustez selon vos besoins, ou laissez la taille
           par défaut si elle convient déjà.
       */
    }

    .qr-code-modal-infos {
        margin-top: 10px;
      }


  }