/* src/styles/UpdatesPage.css */
.updates-container {
  padding: 2rem;
  max-width: 800px;
  margin: 100px auto;
}

.updates-container h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.updates-container section {
  margin-bottom: 2rem;
}

.updates-container ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
}

/* Responsive pour tablettes */
@media (max-width: 768px) {
  .updates-container {
    padding: 1rem;
  }

  .updates-container h1 {
    font-size: 1.5rem;
  }
}

/* Responsive pour mobiles */
@media (max-width: 480px) {
  .updates-container {
    padding: 0.5rem;
  }

  .updates-container h1 {
    font-size: 1.3rem;
  }

  .updates-container ul {
    padding-left: 1rem;
  }
}
