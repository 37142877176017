/* ----------- Variables de couleurs & typographie ----------- */
:root {
    --background-color: #121212;
    --card-background-color: #1e1e1e;
    --text-color: #ffffff;
    --secondary-text-color: #bbb;
    --button-primary-bg: #1E88E5;
    --button-primary-hover-bg: #1565c0;
    --copy-message-bg: rgba(0, 0, 0, 0.8);
   --button-danger-bg: #e53935;
   --button-danger-hover-bg: #c62828;
    --copy-message-text: #fff;
    --box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
    --border-radius: 8px;
    --font-family: 'Poppins', sans-serif;
  }
  
  /* ----------- Reset global ----------- */
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  /* ----------- Container Principal ----------- */
  .dashboard-page {
    padding: 20px;
    min-height: 100vh;
  }
  
  /* ----------- Titre principal ----------- */
  .dashboard-heading {
    font-size: 1.5rem;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* ----------- Message de copie ----------- */
  .copy-message {
    position: absolute;
    pointer-events: none;
    background-color: var(--copy-message-bg);
    color: var(--copy-message-text);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    white-space: nowrap;
    animation: fade-in-out 2s ease;
    z-index: 9999;
  }
  
  @keyframes fade-in-out {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  /* ----------- État "aucun morceau" ----------- */
  .no-accepted-tracks {
    text-align: center;
    color: var(--secondary-text-color);
    font-style: italic;
  }
  
  /* ----------- Cartes des morceaux ----------- */
  .dashboard-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--card-background-color);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .dashboard-card-content {
    flex: 1;
    margin-right: 15px;
    min-width: 0; /* Pour permettre le texte de se réduire au besoin */
  }
  
  /* Titre + artiste cliquables */
  .dashboard-title,
  .dashboard-artist {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
  }
  
  .dashboard-title {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
  }
  
  .dashboard-artist {
    font-size: 0.8rem;
    font-style: italic;
    margin: 5px 0;
  }
  
  /* Genres */
  .track-genres {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 0.8rem;
    color: var(--secondary-text-color);
  }
  
  .genre-badge {
    border-radius: 10px;
    padding: 5px;
    margin-right: 5px;
  }
  
  /* Bouton "Joué" */
  .btn-primary {
    background-color: var(--button-primary-bg);
    border: none;
    color: var(--text-color);
    padding: 8px 16px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex-shrink: 0;
    width: auto;
  }
  
  .btn-primary:hover {
    background-color: var(--button-primary-hover-bg);
  }
  /* Bouton "Refuser" */
 .btn-danger {
     background-color: var(--button-danger-bg);
     border: none;
     color: var(--text-color);
     padding: 8px 16px;
     font-size: 0.9rem;
     font-weight: bold;
     border-radius: 4px;
     cursor: pointer;
     transition: background-color 0.3s ease;
     flex-shrink: 0;
     width: auto;
      /* Pour centrer parfaitement le texte */
 display: flex;
 justify-content: center;
 align-items: center;
   }
  
   .btn-danger:hover {
     background-color: var(--button-danger-hover-bg);
   }
  
   /* Conteneur pour aligner les deux boutons */
   .djview-action-buttons {
    display: flex;
    gap: 10px;       /* Espace entre les boutons */
    
    align-items: center;
    justify-content: center;     /* Centre horizontalement */
  }

  .droppable-container {
    display: flex;
    flex-direction: column;
    gap: 0px; /* petit écart entre les items */
  }



  
  /* ----------- Responsivité ----------- */
  
  /* Pour les écrans < 768px */
  @media (max-width: 768px) {
    .dashboard-card {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .dashboard-card-content {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .dashboard-title,
    .dashboard-artist {
      max-width: 100%;
    }
  
    .btn-primary {
      width: 100%;
      text-align: center;
    }

    .djview-action-buttons {
      flex-direction: column; 
      width: 100%;     /* Le conteneur prend toute la largeur de la carte */
    }
    
    .djview-action-buttons button {
      width: 100%; 
    }
  }
  
  /* Pour les écrans < 480px */
  @media (max-width: 480px) {
    .btn-primary {
      font-size: 1rem;
      padding: 10px 16px;
    }
  }