/* ----------------------------------- */
/*    SendMessagePage.css              */
/* ----------------------------------- */

/* On peut réutiliser les variables globales du site
   ou recopier quelques variables essentielles :
*/
:root {
  --background-color: #1b1b1b;
  --text-color: #ffffff;
  --secondary-text-color: #cccccc;
  --button-primary-bg: #1e88e5;
  --button-primary-hover-bg: #1565c0;

  --font-family: "Poppins", sans-serif;
  --transition-default: 0.3s ease;
}

/* Conteneur principal */
.send-message-container {
  background-color: var(--background-color);
  min-height: 100vh;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* centre horizontalement */
  justify-content: flex-start;
  box-sizing: border-box;
  color: var(--text-color);
  font-family: var(--font-family);
}

/* Titre */
.send-message-title {
  margin-bottom: 20px;
  font-size: 1.4rem;
  text-align: center;
  font-weight: bold;
}

/* Champ de texte */
.send-message-textarea {
  width: 100%;
  max-width: 600px;
  background-color: #2a2a2a;
  color: var(--text-color);
  border: 1px solid #444;
  padding: 15px;
  border-radius: 6px;
  font-size: 1rem;
  resize: none;
  margin-bottom: 20px;
  transition: border var(--transition-default),
    background-color var(--transition-default);
}

.send-message-textarea:focus {
  outline: none;
  border: 1px solid var(--button-primary-hover-bg);
  background-color: #333;
}

/* Conteneur du bouton */
.send-message-button-container {
  display: flex;
  justify-content: center;
}

/* Bouton d'envoi */
.send-message-button {
  background-color: var(--button-primary-bg);
  color: var(--text-color);
  border: none;
  border-radius: 6px;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color var(--transition-default);
}

.send-message-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.send-message-button:not(:disabled):hover {
  background-color: var(--button-primary-hover-bg);
}

/* ------------------------------ */
/*  Responsivité                  */
/* ------------------------------ */
@media (max-width: 600px) {
  .send-message-title {
    font-size: 1.2rem;
  }

  .send-message-textarea {
    font-size: 0.9rem;
    padding: 10px;
  }

  .send-message-button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}
