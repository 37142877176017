/* src/styles/AboutPage.css */

/* Conteneur global */
.about-page {
    background-color: #121212;
    min-height: 100vh;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    padding-top: 10px;
  }
  
  /* Contrainte : 
     Le <MainHeader /> est en position fixe, 
     donc on laisse un padding-top si nécessaire */
  .about-container {
    max-width: 900px;
    margin: 80px auto 30px; /* top, auto left/right, bottom */
    padding: 20px;
  }
  
  /* Titre principal */
  .about-title {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Sous-titre */
  .about-subtitle {
    font-size: 1.4rem;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #1E88E5; /* bleu */
  }
  
  /* Sections */
  .about-section {
    margin-bottom: 30px;
  }
  
  /* Texte principal */
  .about-text {
    font-size: 1rem;
    line-height: 1.6;
    color: #cccccc;
    margin-bottom: 10px;
  }
  
  /* Emphase / zone mise en avant */
  .about-section.emphasis {
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  /* Responsive : sur mobile, on réduit un peu les marges, les tailles */
  @media (max-width: 857px) {
    .about-container {
      margin: 60px auto 20px;
      padding: 10px;
    }
    .about-page {
      padding-top: 60px;
    }
    .about-title {
      font-size: 1.6rem;
    }
    .about-subtitle {
      font-size: 1.2rem;
    }
    .about-text {
      font-size: 0.95rem;
      line-height: 1.5;
    }
  }