.djplus-badge {
    font-weight: 600;
   margin-left: 10px;
    background: rgb(255, 165, 21);
    color: #1b1b1b; /* Contraste sur fond doré */
    padding: 5px 10px;
    transform: translate(5%, -20%);
    box-shadow: 0 2px 8px rgba(0,0,0,0.3);
    z-index: 10; /* pour passer devant la carte */
    border-radius: 3px;
    text-decoration: none;
   }