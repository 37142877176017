/* -------------------------- */
/*        Variables           */
/* -------------------------- */
:root {
  /* Couleurs */
  --background-color: #1b1b1b;
  --card-background-color: #1e1e1e;
  --card-hover-bg: #252525;
  --text-color: #ffffff;
  --secondary-text-color: #cccccc;
  --status-text-color: #b0b0b0;
  --button-primary-bg: #1e88e5;
  --button-primary-hover-bg: #1565c0;
  --button-propose-hover-bg: #ffffff;
  --button-propose-hover-text: #000000;
  --cooldown-bg: #333;
  --cooldown-text: #d8d8d8;
  --cooldown-bar-bg: #1e88e5;
  --cooldown-bar-hover-bg: #1565c0;
  --modal-overlay-bg: rgba(0, 0, 0, 0.6);
  --modal-content-bg: #222;
  --alert-success-bg: #1e5128;

  /* Dimensions */
  --card-border-radius: 8px;
  --button-border-radius: 4px;
  --search-input-border-radius: 4px;

  /* Typographie */
  --font-family: "Poppins", sans-serif;
  --font-family-bold: "Poppins-bold", sans-serif;

  /* Effets */
  --box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
  --transition-default: 0.3s ease;
  --transition-smooth: 0.5s ease;
}

/* Supprimer la croix par défaut dans les champs de type search */
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

/* -------------------------- */
/*     Conteneur principal    */
/* -------------------------- */
.search-page-container {
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0;
}
.event-name {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #ffffff;
  font-size: 1.5rem;
}

header .logo-container {
  text-align: center;
}
/* -------------------------- */
/*         Cartes Track       */
/* -------------------------- */
.card-custom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 10px 10px 20px 10px;
  background-color: var(--card-background-color);
  border: 0;
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  color: var(--text-color);
  transition: background-color var(--transition-default),
    transform 0.2s ease-in-out;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.card-custom:hover {
  background-color: var(--card-hover-bg);
}

.card-custom.accepted {
  background-color: #005f37;
}

.card-custom.refused {
  background-color: #510000;
}

.card-custom.played {
  background-color: #000000;
}

.card-custom .divider {
  width: 100%;
  border-top: 2px solid #868686;
  margin-top: 10px;
  margin-bottom: 10px;
}
.divider-titre {
  justify-items: center;
  align-items: center;
  width: 100%;
  border-top: 2px solid #868686;
  margin-bottom: 10px;
}

/* Container interne de la carte */
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-left: 0px;
  padding-right: 5px;
  padding-left: 5px;
  flex-wrap: nowrap;
  transition: all var(--transition-smooth);
  position: relative;
}

/* Zone texte */
.text-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0px;
  overflow: hidden;
  justify-content: center;
  padding-right: 0;
  margin-left: 0px;
  transition: all var(--transition-smooth);
}

/* Titre & artiste */
.card-custom .title,
.card-custom .artist {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-family);
}

.card-custom .title {
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0px 0 0 0;
  margin-top: 6px;
  margin-right: 10px;
  color: var(--text-color);
}

.card-custom .artist {
  font-size: 0.85rem;
  font-family: var(--font-family-bold);
  color: var(--secondary-text-color);
  margin: 5px 0;
  margin-right: 5px;
}

@keyframes blink-background {
  0% {
    background-color: #1e1e1e; /* Couleur de base */
  }
  50% {
    background-color: #0f5b84; /* Couleur de clignotement */
  }
  100% {
    background-color: #1e1e1e; /* Retour à la couleur de base */
  }
}

.card-custom.blinking {
  animation: blink-background 1.5s infinite ease-in-out; /* Animation fluide */
}

/* Statut */
.status {
  font-size: 0.8rem;
  text-align: center;
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  color: var(--status-text-color);
  flex-shrink: 0;
  transition: all var(--transition-smooth);
}

.status-text {
  font-size: 0.75rem;
  padding: 5px 10px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-text.accepted {
  color: #c4c4c4;
  padding: 5px 20px;
}

.status-text.refused {
  color: #c4c4c4;
  padding: 5px 35px;
}

.status-text.played {
  color: #c4c4c4;
}

/* Description pour morceaux refusés */
.refused-description {
  font-size: 0.8rem;
  color: var(--secondary-text-color);
  font-family: var(--font-family);
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 10px;
  line-height: 1.4;
  text-align: center;
}

/* Queue info (position / attente) */
.card-custom .queue-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  color: var(--secondary-text-color);
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  width: 100%;
  flex-wrap: nowrap;
}

.card-custom .queue-info .important {
  color: #ffffff;
  text-shadow: 0px 0px 5px #1e88e5;
  font-size: 1rem;
  font-weight: bold;
}

.card-custom .queue-info span {
  margin-left: 0px;
  margin-right: 0px;
}

/* -------------------------- */
/*         Boutons            */
/* -------------------------- */
.cooldown-button {
  background-color: var(--cooldown-bg);
  color: var(--cooldown-text);
  font-size: 0.9rem;
  padding: 0;
  border-radius: var(--button-border-radius);
  width: 100px;
  height: 35px;
  border: none;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.cooldown-button:hover .cooldown-bar {
  background-color: var(--cooldown-bar-hover-bg);
}

.cooldown-button .cooldown-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--cooldown-bar-bg);
  transition: width 1s linear, background-color 0.3s ease;
  border-radius: var(--button-border-radius);
  overflow: hidden;
}

.cooldown-button span {
  position: relative;
  z-index: 1;
}

.propose-button {
  background-color: var(--button-primary-bg);
  color: var(--text-color);
  font-size: 0.9rem;
  padding: 0;
  border-radius: var(--button-border-radius);
  border: none;
  cursor: pointer;
  transition: background-color var(--transition-default);
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: var(--font-family);
}

.propose-button:hover {
  background-color: var(--button-propose-hover-bg);
  color: var(--button-propose-hover-text);
  font-weight: 800;
}

/* Bouton "✕" (clear search) */
.searchBar-cross {
  color: var(--text-color);
  background-color: #333;
  border: 1px solid #444;
  padding: 0 15px;
  font-size: 1.2rem;
  border-radius: var(--button-border-radius);
  cursor: pointer;
  transition: background-color var(--transition-default),
    color var(--transition-default);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.searchBar-cross:hover {
  background-color: #444;
}

/* Boutons "classic-button" pour les modales */
.classic-button {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--button-primary-bg);
  color: var(--text-color);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  transition: all var(--transition-default);
  white-space: nowrap;
  text-align: center;
}

.classic-button:hover {
  background-color: var(--button-propose-hover-bg);
  color: var(--button-propose-hover-text);
  font-weight: 800;
}

.classic-button.fill-center {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.classic-button.fill-center::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-50%) scaleX(0);
  transform-origin: center center;
  background-color: #efefef0f;
  animation-name: fillFromCenter;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.fill-center-120::before {
  animation-duration: 60s; /* Remarque : 60s */
}

.fill-center-10::before {
  animation-duration: 30s; /* Remarque : 30s */
}

@keyframes fillFromCenter {
  0% {
    transform: translateX(-50%) scaleX(0);
  }
  100% {
    transform: translateX(-50%) scaleX(1);
  }
}

/* -------------------------- */
/*        Modales             */
/* -------------------------- */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modal-overlay-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: all 0.5s ease;
}

.modal-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.modal-content-accepted,
.modal-content-played {
  display: inline-block;
  background-color: var(--modal-content-bg);
  padding: 25px;
  border-radius: var(--card-border-radius);
  text-align: center;
  width: 80%;
  max-width: 85%;
  z-index: 1001;
  font-family: var(--font-family);
  animation: fadeInScale 0.3s ease forwards;
}

@keyframes fadeInScale {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeOutScale {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

.modal-content-accepted.closing,
.modal-content-played.closing {
  animation: fadeOutScale 0.3s ease forwards;
}

/* Titre track accepté/joué */
.acceptedtrackname {
  font-size: clamp(0.1rem, 5vw, 2.5rem);
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-shadow: 0px 0px 5px #1e88e5;
}

/* -------------------------- */
/*       Alertes              */
/* -------------------------- */
.alert {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px;
  text-align: center;
  font-size: 1rem;
  transition: all var(--transition-smooth);
}

.alert-success {
  background-color: var(--alert-success-bg);
  color: var(--text-color);
}

/* -------------------------- */
/*   Barre de recherche       */
/* -------------------------- */
.input-group {
  position: relative; /* Déjà présent, vérifiez qu'il existe */
}

.input-group > div:first-of-type {
  position: absolute;
  right: 50px; /* Ajustez cette valeur selon l'espacement désiré entre le loader et le bouton de la croix */
  top: 50%;
  transform: translateY(-50%);
}

.global-loader {
  position: absolute;
  right: 50px; /* Ajustez selon vos besoins */
  top: 50%;
  transform: translateY(-50%);
}

.search-input {
  background-color: var(--card-background-color);
  color: var(--text-color);
  border: 1px solid #444;
  padding: 10px 15px;
  border-radius: var(--search-input-border-radius);
  font-size: 1rem;
  -webkit-text-fill-color: var(--text-color);
  width: 100%;
  box-sizing: border-box;
  transition: background-color var(--transition-default),
    color var(--transition-default), border var(--transition-default);
}

.search-input:focus {
  background-color: #2e2e2e;
  color: var(--text-color);
  border: 1px solid var(--button-primary-hover-bg);
  outline: none;
}

.search-input:hover {
  background-color: #2a2a2a;
}

/* -------------------------- */
/*         Spinner            */
/* -------------------------- */
.spinner {
  position: absolute;
  right: 50px; /* Ajustez cette valeur pour le placer avant le bouton de la croix */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
}
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--button-primary-bg);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@media (min-width: 769px) {
  .card-custom {
    margin-bottom: 20px; /* Plus d'espace entre les cards */
  }
}
/* Écrans moyens (max-width: 768px) */
@media (max-width: 768px) {
  .card-custom {
    padding: 10px;
    margin: 0 5px 20px 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    max-width: 100%;
    box-sizing: border-box;
  }

  .dashboard-card-content {
    flex: 1;
    margin-right: 0;
  }

  .dashboard-title,
  .dashboard-artist {
    max-width: 90%;
  }

  .btn-primary {
    font-size: 1rem;
    width: 120px;
  }
}

/* Très petits écrans (max-width: 600px) */
@media (max-width: 600px) {
  .card-custom {
    padding: 10px;
    margin: 0 5px 20px 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    max-width: 100%;
    box-sizing: border-box;
  }

  .card-custom:hover {
    background-color: none;
    transform: scale(1);
  }

  .card-custom .title {
    font-size: 0.85rem;
  }

  .card-custom .artist {
    font-size: 0.75rem;
  }

  .card-custom .status {
    min-width: 70px;
    height: 30px;
    font-size: 0.75rem;
  }

  .cooldown-button,
  .propose-button {
    width: 90px;
    height: 38px;
    font-size: 0.85rem;
  }
  .propose-button:hover {
    background-color: #1565c0;
    color: #ffffff;
  }

  .container {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .alert {
    font-size: 0.9rem;
    padding: 10px;
  }

  h1 {
    font-size: 1.3rem;
  }

  h2 {
    font-size: 0.9rem;
  }

  .input-group button {
    padding: 5px 8px;
    font-size: 0.8rem;
  }

  .spinner {
    width: 20px;
    height: 20px;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
  }
}

/* Très petits écrans (max-width: 480px) */
@media (max-width: 480px) {
  .btn-primary {
    font-size: 1.1rem;
    width: 150px;
  }
}
