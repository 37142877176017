/**************************************
 * CollaboratorsModal.css
 * Inspiré du style général de l'app
 **************************************/

/* On réutilise les mêmes classes 'modal-overlay' et 'modal-content'
   que dans EvenementsManager.css, pour rester cohérent. */

/* =========================================================================
   1. Overlay de la modale
   ========================================================================= */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999; /* Au-dessus de tout */
}

/* =========================================================================
     2. Contenu de la modale
     ========================================================================= */
.modal-content {
  background-color: #1b1b1b;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 550px; /* Largeur max: 550px */
  max-height: 80vh; /* Hauteur max: 80% de la fenêtre */
  overflow-y: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
  color: #fff; /* Couleur texte en blanc */
  font-family: "Poppins", sans-serif;
  display: flex; /* Ajouté */
  flex-direction: column;
}

/* Scrollbar personnalisée (facultatif) */
.modal-content::-webkit-scrollbar {
  width: 8px;
}
.modal-content::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 4px;
}

/* =========================================================================
     3. Titre de la modale
     ========================================================================= */
.collaborators-modal-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 10px;
  color: #ffffff;
}

/* =========================================================================
     4. Paragraphe d'infos 
     ========================================================================= */
.collaborators-info-text {
  color: #bbb;
  line-height: 1.4;
  font-size: 0.95rem;
  margin-bottom: 1rem;
  white-space: pre-line; /* Pour gérer les retours à la ligne du texte infoText */
}

/* =========================================================================
     5. Section d'invitation (email/pseudo)
     ========================================================================= */
.collaborators-invite-section {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.collaborators-invite-label {
  font-weight: 600;
  color: #fff;
}

.collaborators-invite-input {
  margin-left: 0.5rem;
  width: 60%;
  padding: 8px 10px;
  background-color: #2a2a2a;
  border: 1px solid #3f3f3f;
  border-radius: 4px;
  color: #fff;
}

.collaborators-invite-button {
  margin-left: 0.5rem;
  padding: 8px 14px;
  border: none;
  border-radius: 4px;
  background-color: #2196f3;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.collaborators-invite-button:hover {
  background-color: #1766ac;
}

/* =========================================================================
     6. Liste des collaborateurs
     ========================================================================= */
.collaborators-list-title {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
}

.collaborators-list {
  list-style: none;
  padding: 0;
  margin-top: 0.5rem;
}

.collaborators-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #fff;
  gap: 8px;
}

/* Avatar ou initiales */
.avatar-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover; /* Au cas où l'image ne soit pas carrée */
}
.avatar-initials {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ccc;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-weight: 600;
}

/* Sélecteur de rôle */
.collaborators-role-select {
  padding: 4px 8px;
  border: 1px solid #3f3f3f;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

/* Bouton retirer */
.remove-collab-btn {
  margin-left: 1rem;
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.remove-collab-btn:hover {
  background-color: #c82333;
}

/* =========================================================================
     7. Bouton "Fermer" 
     ========================================================================= */
.fermer-modal-btn {
    margin-top: 1.5rem; /* pousse le bouton en bas si contenu plus grand */
  padding: 10px 0;
  background-color: #9e9e9e;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Le bouton prend toute la largeur */
  text-align: center;
}

.fermer-modal-btn:hover {
  background-color: #7e7e7e;
}

/* =========================================================================
     8. État de chargement
     ========================================================================= */
.loading-text {
  color: #ccc;
  margin-top: 0.5rem;
  font-style: italic;
}
