.fixed-event-button {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--button-primary-bg);
    color: #fff;
    padding: 10px 20px;
    border: 1px solid #ffffff4a; /* Bordure blanche */
    border-radius: 8px;
    font-size: 1rem;
    text-decoration: none;
    z-index: 1000;
    transition: background-color var(--transition-default), box-shadow var(--transition-default);
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.529); /* Ombre par défaut */
  }
  
  .fixed-event-button:hover {
    background-color: var(--button-primary-hover-bg);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.202); /* Ombre plus marquée au survol */
  }
  
@media (max-width: 768px) {
    .fixed-event-button {
    position: fixed;
    padding: 10px 20px;
    width: 80%;
    font-size: 0.9rem;
  }
}