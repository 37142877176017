.privacy-page {
  background-color: #1b1b1b;
  margin-top: 80px;
}
.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #fff;
  background-color: #1b1b1b;
}

.privacy-container h1 {
  text-align: center;
  margin-bottom: 30px;
}

.privacy-container section {
  margin-bottom: 25px;
}

.privacy-container h2 {
  color: #1e88e5;
  margin-bottom: 10px;
}

.privacy-container p {
  line-height: 1.6;
}

.privacy-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

.privacy-container a {
  color: #1e88e5;
  text-decoration: none;
}

.privacy-container a:hover {
  text-decoration: underline;
}
