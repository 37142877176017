/* main-header global */
.main-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 8px 30px;
  background-color: rgba(30, 30, 30, 0.7);
  backdrop-filter: blur(8px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
}

/* (1) Colonne gauche = logo */
.logo-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* (2) Nav (toujours affiché) */
.nav-links {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: 20px;
}
.nav-links a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 8px 0;
  transition: color 0.3s ease;
}
.nav-links a:hover {
  color: #ccc;
}
.nav-links a.active {
  color: #eee;
}
/* Magic line */
.magic-line {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #fff;
  pointer-events: none;
  transition: left 0.3s, width 0.3s;
}

/* (3) Colonne droite = AuthMenu */
.auth-menu-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.desktop-logo {
  display: block;
  margin-top: 5px;
}

.mobile-logo {
  display: none;
}

/* ------------------------------- */
/* Styles pour le Hamburger Menu */
/* ------------------------------- */

/* Par défaut, on ne l'affiche pas sur desktop */
.hamburger-menu {
  display: none;
  cursor: pointer;
  z-index: 11000; /* Au-dessus du header */
}

/* Style de l'icône hamburger */
.hamburger-icon {
  width: 25px;
  height: 3px;
  background-color: #fff;
  display: block;
  position: relative;
}
.hamburger-icon::before,
.hamburger-icon::after {
  content: "";
  width: 25px;
  height: 3px;
  background-color: #fff;
  position: absolute;
  left: 0;
}
.hamburger-icon::before {
  top: -8px;
}
.hamburger-icon::after {
  top: 8px;
}

/* ===========================
   Menu qui s'ouvre sous le header
   =========================== */
.menu-modal {
  position: fixed;
  display: flex;
  top: 90px; /* Assure-toi que cette valeur correspond bien à la hauteur du header */
  left: 0;
  width: 100%;
  height: 40px; /* La hauteur correspondante au contenu (texte) */
  justify-content: center;
  align-items: center;

  /* On applique le même fond et le même blur que le header */
  background-color: rgba(30, 30, 30, 0.7);
  backdrop-filter: blur(8px);

  /* Animation de slide du haut vers le bas */
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  z-index: 9998;
}

/* Quand on active la classe "open", on le fait glisser vers le bas */
.menu-modal.open {
  transform: translateY(0);
}

/* Contenant principal du menu */
.menu-modal-content {
  /* On supprime l'overflow et la hauteur forcée */
  overflow-y: visible;
  height: auto;

  /* On peut mettre un léger padding pour que le texte 
       ne colle pas trop aux bords */
  padding: 10px 20px;

  /* Pour gérer la disposition horizontale directement dans .menu-modal-content
       ou dans le <ul>, à ta convenance */
  display: flex;
  justify-content: center; /* Centre horizontalement */
}

/* Liste horizontale */
.menu-modal-content ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 20px; /* Espacement entre chaque lien, sinon tu peux utiliser margin sur <li> */
}

/* Les items n’ont plus besoin de marge verticale */
.menu-modal-content li {
  margin: 0;
}

/* Style des liens */
.menu-modal-content a {
  display: block; /* Clique sur toute la zone du texte */
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  padding: 8px 0; /* Ajuste selon la hauteur souhaitée */
  transition: color 0.3s;
}

.menu-modal-content a:hover {
  color: #ccc;
}


/* ===========================
   VERSION MOBILE
   =========================== */
@media (max-width: 844px) {
  /* 1) Le header : 3 colonnes
       - 1re col : vide (pour l'espace à gauche)
       - 2e col : logo (centré)
       - 3e col : AuthMenu (bouton connexion) */

  .hamburger-menu {
    display: flex;
    align-items: center;
    grid-column: 1;
  }

  .main-header {
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr; /* Une seule rangée */
    align-items: center;
    padding: 0px 20px;
    height: 100px; /* Hauteur fixe du header */
  }

  .main-header.expanded {
    height: 150px; /* Hauteur fixe du header */
  }

  /* 2) Le logo passe dans la colonne du milieu (2/3) */
  .logo-left {
    grid-column: 2; /* place la div .logo-left dans la 2e colonne */
    display: flex;
    justify-content: center; /* centre le logo */
  }

  /* 3) Le menu de connexion passe sur la colonne de droite (3/3) */
  .auth-menu-right {
    grid-column: 3;
    display: flex;
    justify-content: flex-end;
  }
  .mobile-menu {
    position: absolute;
    top: 113px; /* Place-le juste en dessous du header (ajustez si besoin) */
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 5px 20px;
    z-index: 100001; /* Pour qu'il apparaisse au-dessus du contenu du header */
    /* Optionnel : on peut ajouter une animation sur le conteneur lui-même */
    /* animation: fadeInSlideDown 0.5s forwards; */
  }

  .mobile-menu li {
    margin: 0;
    /* Départ de l'animation : décalé vers le haut et transparent */
    opacity: 0;
    transform: translateY(-10px);
    animation: fadeInSlideDown 0.5s forwards;
  }

  /* Animation décalée pour un effet "stagger" */
  .mobile-menu li:nth-child(1) {
    animation-delay: 0.1s;
  }
  .mobile-menu li:nth-child(2) {
    animation-delay: 0.2s;
  }
  .mobile-menu li:nth-child(3) {
    animation-delay: 0.3s;
  }
  .mobile-menu li:nth-child(4) {
    animation-delay: 0.4s;
  }

  /* Définition de l'animation */
  @keyframes fadeInSlideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .mobile-menu ul {
    display: flex;
    flex-direction: row;
    gap: 10px; /* Réduit l'espacement entre les liens */
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: nowrap; /* Forcer tous les éléments sur une seule ligne */
  }

  .mobile-menu a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    padding: 8px 0;
    transition: color 0.3s;
    font-size: 1rem; /* Taille réduite pour s'adapter à l'écran */
    white-space: nowrap; /* Empêche le retour à la ligne */
  }

  .mobile-menu a:hover {
    color: #ccc;
  }

  /* 4) Masquer les liens de navigation sur mobile */
  .nav-links {
    display: none;
  }

  .desktop-logo {
    display: none;
  }
  .mobile-logo {
    display: block;
    margin-top: 10px;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .mobile-logo .full-container {
    margin: 0;
    padding: 0;
    padding: 0;
  }

  /* Style du lien "Charts" dans le menu mobile */
.mobile-menu .charts-mobile-link {
  color: #1e88e5 !important; /* Bleu */
  font-weight: bold;
  text-transform: uppercase;
}

/* Au survol */
.mobile-menu .charts-mobile-link:hover {
  color: #1565c0 !important; /* Bleu foncé */
}

}

.nav-links a.charts-button {
  background-color: #007bff; /* Bleu */
  padding: 8px 15px;
  border-radius: 4px;
  color: #fff !important;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.nav-links a.charts-button:hover {
  background-color: #0056b3;
}
