.spotify-dashboard {
  background-color: #121212;
  color: #ffffff;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px; /* Réduit l'espace entre les éléments internes */
  flex: 1; /* Prend tout l'espace disponible */
  min-width: 0;
}

.spotify-link {
  top: 30px;
  right: 30px;
  position: absolute;
  cursor: pointer;
}

.dashboard-columns {
  display: flex;
  gap: 20px; /* Espace horizontal entre les deux colonnes */
}

.dashboard-column {
  flex: 1; /* Prend la même largeur pour chaque colonne */
}

.playlist-edit-button {
  position: block;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: none;
  border-radius: 8px;
  border-color: #ffffff71;
  color: #ffffff;
  margin-left: 1rem;
  top: 0px;
}

.playlist-edit-button:hover {
  background-color: #ffffff;
  color: #1b1b1b;
  transition: 0.3s ease-in-out;
}

/* Zone englobante pour "Connecté" et le bouton de déconnexion */
.connected-wrapper {
  position: relative;
  display: inline-block;
  padding-bottom: 50px; /* Permet de garder le bouton dans la zone de survol */
  margin-top: 50px;
}

.connected-text {
  color: green;
  font-weight: bold;
  cursor: pointer;
}

.spotify-logout-btn {
  position: absolute;
  top: 25%;
  left: 0;
  margin-top: 5px;
  padding: 5px 10px;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  display: none;
}

.connected-wrapper:hover .spotify-logout-btn {
  display: block;
}

/* Nouveau style pour le conteneur des cards de choix de playlist */
.playlist-choice-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0;
}

@media (max-width: 768px) {
  .dashboard-columns {
    flex-direction: column;
    gap: 10px; /* On réduit l'espacement si besoin */
  }
  .playlist-edit-button {
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 10px 70px;
    margin-top: 10px;
    margin-left: 20px;
    top: 0px;
  }

  .playlist-choice-cards {
    flex-direction: column;
    gap: 1rem; /* Ajustez l'espacement vertical selon vos besoins */
    align-items: center; /* Pour centrer les cards */
  }
}
