.cgv-container {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 80px;
  padding: 20px;
  color: #ffffff;
}

.cgv-container h1 {
  text-align: center;
  margin-bottom: 30px;
}

.cgv-container section {
  margin-bottom: 25px;
}

.cgv-container h2 {
  color: #1e88e5;
  margin-bottom: 10px;
}

.cgv-container p {
  line-height: 1.6;
}
