/* ============================= */
/*          Variables           */
/* ============================= */
:root {
  --background-color: #121212;
  --card-background-color: #1e1e1e;
  --text-color: #ffffff;
  --secondary-text-color: #cccccc;
  --button-primary-bg: #1e88e5;
  --button-primary-hover-bg: #1565c0;
  --footer-bg: #1e1e1e;
  --section-bg-alt: #1b1b1b;
  --transition-default: 0.3s ease;
  --font-family: "Poppins", sans-serif;

  /* Largeur max du contenu à l'intérieur de chaque section */
  --section-inner-max-width: 900px;
}

/* ============================= */
/*       Reset & body           */
/* ============================= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-family);
  line-height: 1.5;
}

/* ============================= */
/*       Container global       */
/* ============================= */
.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 60px;
}

/* ============================= */
/*          HERO VIDEO           */
/* ============================= */
/* Le conteneur global : position: relative pour gérer la superposition */
.hero-video-section {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  margin-bottom: 0px;
}

/* La vidéo est placée en fond (z-index: 1) */
.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* Derrière tout le reste */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* L’overlay (le calque supérieur) */
.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; /* On le place au-dessus de la vidéo mais en-dessous du logo */
  width: 100%;
  height: 100%;

  /* On peut centrer directement le logo 
     si on l’ajoute DANS .hero-overlay */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Le voile sombre + le blur en pseudo-élément 
   avec un z-index : 2 ou 3, du moment qu’il 
   reste derrière le logo. Mettons-le à 2 */
.hero-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; /* Au même niveau que l'overlay, mais toujours derrière le logo */
  width: 100%;
  height: 100%;

  /* Voile + flou */
  background-color: rgba(0, 0, 0, 0.4); /* le voile sombre */
  backdrop-filter: blur(5px); /* l’intensité du flou */
}

/* Le logo doit être au-dessus du voile ET au-dessus du pseudo-élément */
.hero-logo {
  position: relative;
  transform: scale(3);
  z-index: 3; /* Plus haut que l’overlay::after */
}

.hero-text {
  position: relative;
  display: block;
  font-size: 1.5rem;
  max-width: 600px;
  top: 80%;
  left: 50%;
  font-weight: 700;
  transform: translate(-50%, -50%);
  z-index: 3; /* Plus haut que l’overlay::after */
  text-align: center;
  color: #ffffff;
}

/************************************/
/*   SECTION : Phone Mockup Image   */
/************************************/
.phone-feature-section {
  display: flex;
  align-items: center;
  justify-content: center; /* <-- On centre horizontalement l’ensemble */
  gap: 40px; /* <-- Espace entre l’image et le texte */
  padding: 60px 20px;

  /* Animation de départ */
  opacity: 0;
  transform: translateX(-100px);
  transition: all 1s ease-in-out;
  max-width: 1000px; /* <-- Largeur max */
  margin: 0 auto; /* <-- Centre la section dans la page */
}

/* Quand la section devient visible (Intersection Observer) */
.phone-feature-section.phone-visible {
  opacity: 1;
  transform: translateX(0);
}

/* Le conteneur de l’image (anciennement "phone-video-container") */
.phone-video-container {
  flex: 0 0 auto; /* On laisse la taille s'adapter au contenu */
  max-width: 20%; /* Ajustez selon la taille souhaitée pour l'image */
  transition: all 2s ease-in-out;
}

/* Image du mockup */
.phone-mockup-video {
  width: 100%;
  border-radius: 50px; /* conservez votre arrondi */
  height: auto;
  display: block;
}

/* Zone de texte */
.phone-feature-text {
  flex: 1;
  max-width: 450px; /* <-- Réduit la largeur du texte */
  color: var(--secondary-text-color);
  text-align: left; /* ou 'center' si vous préférez centrer le texte */
}

/* ============================= */
/*    SPOTIFY   PLAYLIST         */
/* ============================= */
.playlist-session-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  max-width: 70%;
  margin: 0 auto;
}

.playlist-session-content {
  text-align: center;
}

.playlist-videos-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.playlist-video {
  flex: 1;
  display: flex;
align-items: center;
  max-width: 70%; /* Pour afficher deux vidéos côte à côte */
  border-radius: 50px; /* Similaire à votre phone-mockup-video */
  overflow: hidden;
  min-width: 200px;
}

.playlist-video video {
  width: 100%;

  height: auto;
  display: block;
}

.playlist-video.small {
  flex: 0 0 21%; /* Vidéo plus petite */
  border-radius: 10px;
}

.playlist-video.large {
  flex: 0 0 100%; /* Vidéo plus grande */
}

/* ============================= */
/*           HEADER             */
/* ============================= */
.home-header {
  position: relative; /* permet de positionner l’enfant en absolute */
  display: flex;
  align-items: center;
  height: 100px; /* si tu veux un header de 100px de haut */
  background-color: var(--card-background-color);
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.header-left-space {
  flex: 1;
}

.home-nav .home-login-btn {
  background-color: var(--button-primary-bg);
  border: none;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color var(--transition-default);
}

/* ============================= */
/*        SECTION HERO          */
/* ============================= */
.home-hero {
  display: flex;
  align-items: center; /* Centre verticalement les éléments */
  justify-content: space-between; /* Espace entre le logo et le bouton */
  text-align: center;
  background-color: var(--card-background-color);
  padding: 20px;
  height: 100px; /* Ajustez la hauteur si nécessaire */
}

.home-hero-content {
  /* Laissez ce max-width si vous voulez garder une largeur raisonnable */
  max-width: 900px;
  width: 100%;
  margin: 10px auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.home-login-btn {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 10; /* pour être certain qu’aucun autre élément ne passe au-dessus */
  background-color: var(--button-primary-bg);
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color var(--transition-default);
}

.home-login-btn:hover {
  background-color: var(--button-primary-hover-bg);
}

/* ============================= */
/*        HOME SECTION          */
/* ============================= */
.home-section {
  position: relative; /* On peut si besoin placer un background complet */
  padding: 50px 20px;
}

/* Permet un background sur la totalité de la largeur */
.fullwidth-section {
  width: 100%;
}

.section-inner {
  max-width: var(--section-inner-max-width);
  margin: 0 auto;
  text-align: center;
}

.home-section.alt-background {
  background-color: var(--section-bg-alt);
}

.section-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.section-text {
  color: var(--secondary-text-color);
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: 20px;
}

.section-list {
  margin-left: 1.5rem;
  margin-bottom: 20px;
}

.section-list li {
  margin-bottom: 8px;
  line-height: 1.6;
}

/* ============================= */
/*      Images additionnelles   */
/* ============================= */
.extra-image-container {
  text-align: center;
  margin-top: 20px;
}

.extra-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  display: block;
}

/* ============================= */
/*   MOCKUP / IMAGES CONTAINER  */
/* ============================= */
.mockup-container {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  justify-content: center;
  margin-top: 30px;
}

.mockup-image,
.mockup-image-inversed {
  width: 200px; /* dimension souhaitée */
  height: auto; /* ratio préservé */
  border-radius: 6px;
}
.mockup-image-inversed {
  transform: scaleX(-1); /* miroir horizontal */
}

/* ============================= */
/*           FOOTER             */
/* ============================= */
.home-footer {
  background-color: var(--footer-bg);
  color: var(--secondary-text-color);
  padding: 20px;
  margin-top: auto;
  text-align: center;
}

.footer-links {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 10px;
}

.footer-links a {
  color: var(--secondary-text-color);
  text-decoration: none;
  transition: color var(--transition-default);
}

.footer-links a:hover {
  color: #999999;
}

.footer-copy {
  font-size: 0.85rem;
}

.fixed-event-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  display: flex; /* On active Flexbox */
  align-items: center; /* Centre verticalement */
  justify-content: center;

  background-color: var(--button-primary-bg);
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #ffffff4a; /* Bordure blanche */
  border-radius: 8px;
  font-size: 1rem;
  text-decoration: none;
  z-index: 1000;
  transition: background-color var(--transition-default),
    box-shadow var(--transition-default);
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.529); /* Ombre par défaut */
}

.fixed-event-button:hover {
  background-color: var(--button-primary-hover-bg);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.202); /* Ombre plus marquée au survol */
}

/* NOUVEAU STYLE POUR LA SECTION "charts-preview-section" */
.charts-preview-section {
  padding: 60px 20px;
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
}

.charts-preview-content .section-title {
  margin-bottom: 30px;
}

.charts-preview-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* espace entre chaque item */
  margin-top: 20px;
}

.chart-item {
  background-color: var(--card-background-color);
  padding: 10px 15px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.2s ease-in-out;
}

.chart-item:hover {
  background-color: #262626;
  transform: translateY(-3px);
}

.chart-item-rank {
  font-weight: 700;
  margin-right: 10px;
  color: var(--button-primary-bg);
}

.chart-item-info {
  flex: 1;
  text-align: left;
}

.chart-item-name {
  font-weight: bold;
}

.chart-item-artists {
  color: var(--secondary-text-color);
  font-size: 0.9rem;
}

.chart-item-count {
  color: var(--secondary-text-color);
}

.go-to-charts-button {
  display: inline-block;
  margin-top: 10px;
  background-color: var(--button-primary-bg);
  color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color var(--transition-default);
}

.go-to-charts-button:hover {
  background-color: var(--button-primary-hover-bg);
}

.mixify-charts-blue {
  /* Tu peux mettre un code hex, RGB ou utiliser ta variable de thème */
  color: var(--button-primary-bg);
}

/* ============================= */
/*          Responsive          */
/* ============================= */
@media (max-width: 768px) {
  .home-container {
    margin-top: 100px;
  }
  .section-title {
    font-size: 1.4rem;
  }
  .section-text {
    font-size: 0.95rem;
  }
  .mockup-image,
  .mockup-image-inversed {
    /* Sur tablettes, on réduit encore un peu ? */
    max-width: 150px;
  }

  .fixed-event-button {
    position: fixed;
    padding: 10px 20px;
    width: 80%;
    font-size: 0.9rem;
  }
  .hero-logo {
    transform: scale(2);
  }

  .hero-video-section {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .hero-text {
    max-width: 90%;
    font-size: 1rem;
  }
  .phone-video-container {
    max-width: 30%; /* Ajustez selon la taille souhaitée pour l'image */
  }
  .phone-mockup-video {
    border-radius: 40px; /* conservez votre arrondi */
  }

  .phone-feature-section {
    gap: 30px; /* <-- Espace entre l’image et le texte */
  }

  .playlist-session-section {

    max-width: 100%;

  }

  .playlist-video {
    max-width: 100%; /* Pour afficher deux vidéos côte à côte */

  }

  
  .playlist-video.small {
    flex: 0 0 20%; /* Vidéo plus petite */
    border-radius: 00px;
  }
  
  .playlist-video.large {
    border-radius: 0px;
    flex: 0 0 80%; /* Vidéo plus grande */
  }

}

@media (max-width: 480px) {
  .home-hero {
    flex-direction: column; /* Colonne pour empiler les éléments */
    height: auto; /* Ajustement automatique de la hauteur */
    padding: 10px 10px;
  }

  .home-login-btn {
    position: static; /* Désactive le positionnement absolu */
  }
  .section-title {
    font-size: 1.2rem;
  }
  .section-text {
    font-size: 0.9rem;
  }
  .mockup-container {
    gap: 10px;
  }
  .mockup-image,
  .mockup-image-inversed {
    /* Sur petits smartphones, on réduit encore plus ? */
    max-width: 120px;
  }

  .phone-video-container {
    max-width: 35%; /* Ajustez selon la taille souhaitée pour l'image */
  }
  .phone-mockup-video {
    border-radius: 35px; /* conservez votre arrondi */
  }

  .phone-feature-section {
    gap: 20px; /* <-- Espace entre l’image et le texte */
  }

  .playlist-session-section {

    max-width: 100%;

  }

  .playlist-video {
    max-width: 100%; /* Pour afficher deux vidéos côte à côte */

  }

  
  .playlist-video.small {
    flex: 0 0 60%; /* Vidéo plus petite */
    border-radius: 00px;
  }
  
  .playlist-video.large {
    border-radius: 0px;
    flex: 0 0 100%; /* Vidéo plus grande */
  }
}
