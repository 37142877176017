/* Ajoutez cette règle pour donner un espace au-dessus de chaque SkeletonCard */
.skeleton-card {
    margin-top: 20px; /* Ajustez cette valeur selon vos préférences */
}

.skeleton {
    background-color: #444;
    border-radius: 4px;
    margin-bottom: 8px;
    animation: pulse 1.5s infinite ease-in-out;
}

/* Simuler le titre (60% de largeur et 20px de hauteur) */
.skeleton.title {
    width: 60%;
    height: 20px;
}

/* Simuler le texte de l’artiste (40% de largeur et 16px de hauteur) */
.skeleton.artist {
    width: 40%;
    height: 16px;
}

/* Animation de pulsation */
@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}