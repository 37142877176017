.modal-settings {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    z-index: 999002;
  }
  
  .modal-settings-content {
    position: relative;
    background-color: #aaaaaa29; 
    backdrop-filter: blur(20px);
    width: 80%;
    max-width: 100%;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    z-index: 1003;
    max-height: 100%;
    overflow-y: auto;
  }
  
  .modal-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .settings-container {
    flex-grow: 1;
    display: flex;
    border: 1px solid #444;
    border-radius: 8px;
    margin-bottom: 20px;
    height: 100%; 
  }
  
  .settings-tabs-left {
    width: 200px;
    background-color: #1e1e1e;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #444;
    padding: 10px 0;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 2;
  }
  
  .settings-tab {
    padding: 15px;
    cursor: pointer;
    font-size: 1rem;
    color: #ccc;
    border-bottom: 1px solid #444;
    transition: background-color 0.3s ease;
  }
  .settings-tab:hover {
    background-color: #333;
  }
  .settings-tab.active {
    background-color: #444;
    font-weight: bold;
    color: #fff;
  }
  
  .settings-tabs-right {
    flex-grow: 1;
    padding: 20px;
    background-color: #2e2e2e;
    overflow-y: auto;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-reinit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    transition: all 0.5s ease;
  }
  
  .modal-reinit-content {
    position: fixed;
    background-color: #3333338a;
    backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 8px;
    z-index: 1002;
    height: 200px;
    width: 350px;
  }
  
  .modal-reinit-text {
    font-size: 1rem;
    text-align: center;
    margin: 0 auto;
  }
  
  .modal-reinit-content-buttons {
    display: flex;
    justify-content: center;
    gap: 60px;
    margin-top: 100px;
    z-index: 1002;
  }
  
  .classic-button {
    background-color: #1E88E5;
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 700;
    padding: 20px 15px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  .classic-button:hover {
    background-color: #1766ac;
    color: #ffffff;
    font-weight: 800;
    transition: all 0.3s ease-in-out;
  }
  
  .classic-button-non {
    background-color: #454545;
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 700;
    padding: 0;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  .classic-button-non:hover {
    background-color: #313131;
    color: #ffffff;
    font-weight: 800;
    transition: all 0.3s ease-in-out;
  }