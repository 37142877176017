/* src/styles/ChartsPage.css */

.charts-page-container {
  /* Laisse de la place pour le header fixe */
  padding: 80px 20px 20px;
  min-height: 100vh;
  background-color: #121212;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.mixify-charts {
  text-align: center;
  font-size: 2.6rem;
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.charts-title {
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 25px;
}

.charts-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* espace entre chaque card */
}

/* Une “card” pour chaque morceau */
.chart-card {
  background-color: #1e1e1e;
  padding: 10px 15px;
  width: 50%;
  min-width: 700px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between; /* Répartit rang à gauche, info au centre, count à droite */
}

.charts-list :hover {
  background-color: #2f2f2f;
}

/* Numéro (rank) */
.chart-rank {
  font-size: 1rem;
  font-weight: 700;
  color: #1e88e5; /* Couleur bleutée */
  text-shadow: #191919 0 0 8px; /* Lueur blanche */
  margin-right: 15px;
}

/* Centre : titre + artistes */
.chart-info {
  flex: 1; /* Prend tout l’espace central */
  display: flex;
  flex-direction: column;
  gap: 3px;
}

/* Titre */
.chart-name {
  font-size: 1rem;
  font-weight: bold;
}

/* Artistes */
.chart-artists {
  font-size: 0.9rem;
  color: #cccccc;
}

/* Nombre total de propositions */
.chart-count {
  font-size: 0.9rem;
  color: #aaaaaa;
  margin-left: 10px;
}
/* Bloc qui contient les deux filtres */
.charts-filters {
  display: flex;
  justify-content: center; /* centre horizontalement */
  align-items: center; /* centre verticalement */
  gap: 20px; /* espace horizontal entre chaque "filter-block" */
  margin-bottom: 30px; /* un petit espace en bas pour séparer des charts */
}

/* Chaque “bloc” de filtre (label + select) */
.filter-block {
  display: flex;
  flex-direction: column; /* label au-dessus du select */
  align-items: flex-start;
}

/* Pour un label cohérent */
.filter-label {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px; /* espace sous le label */
}

/* Style commun pour tous les <select> */
.charts-select {
  padding: 8px 12px;
  font-size: 1rem;
  border-radius: 5px;
  background-color: #1e1e1e;
  color: #fff;
  border: 1px solid #444;
  outline: none; /* enlève l’outline par défaut au focus, si tu veux */
  cursor: pointer; /* curseur main */
}

/* Au survol */
.charts-select:hover {
  background-color: #292929;
}

/* Optionnel: style du select en mode focus/cliqué */
.charts-select:focus {
  border: 1px solid #666;
}

.subscribe-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  /* on peut aussi mettre margin: 0 auto; pour centrer s'il est en inline-block */
  margin: 0 auto;
  /* éventuellement, forcer un background particulier */
  background-color: #1a1a1a;
  color: #fff;
  border: 2px solid #1e88e5;
  border-radius: 30px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

/* Responsive éventuel */
@media (max-width: 768px) {
  .chart-card {
    width: 90% !important;
    padding: 8px 12px;
    min-width: 300px;
  }
  .charts-title {
    font-size: 1.4rem;
  }
  .chart-rank {
    font-size: 0.9rem;
  }
  .chart-name {
    font-size: 0.9rem;
  }
  .chart-artists {
    font-size: 0.8rem;
  }
  .chart-count {
    margin-top: 5px;
    font-size: 0.8rem;
  }

  .charts-filters {
    flex-direction: column; /* on les empile sur mobile */
    align-items: center; /* centrer horizontalement */
    justify-content: center; /* également utile si tu veux centrer verticalement */
  }

  .filter-block {
    /* Si tu veux que chaque filtre prenne sa largeur auto et soit centré */
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center; /* centre label + select horizontalement */
  }

  .subscribe-btn {
    width: 80%; /* 80% de la largeur de l'écran */
    max-width: 300px;
    font-size: 0.9rem;
  }
}
