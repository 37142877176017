.full-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  z-index: 2000;
}

.line {
  flex: 1;
  height: 2px; /* Épaisseur de la ligne */
  margin-bottom: 35px;
  background-color: #ffffff6e; /* Couleur des lignes */
  margin: 0 8px;
}
.logo-container {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    cursor: default; /* Change le curseur pour indiquer que c'est cliquable */
    text-decoration: none; /* Enlève le soulignement par défaut des liens */
  }
  
  .logo-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #ffffff; /* Couleur principale */
  }
  
  .logo-subtitle {
    font-size: 0.8rem;
    font-weight: 400;
    color: #ffffff; /* Couleur secondaire */
    margin-top: -5px;
  }