/* SubscriptionPage.css | Dark Theme Edition
   Version 1.0
*/

/* Variables de couleurs pour le thème sombre */
:root {
  --primary-color: #1e88e5; /* Bleu principal */
  --secondary-color: #27293d; /* Fond des cartes, un gris sombre */
  --accent-color: #ffbb00; /* Couleur accent (doré) */
  --background-color: #1e1e2c; /* Fond global sombre */
  --text-color: #ffffff; /* Couleur du texte (blanc) */
  --secondary-text-color: #cccccc; /* Gris clair pour sous-titre */
  --font-family: "Poppins", sans-serif;
}

/* RESET de base */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Corps de la page */
body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Container principal de la page */
.subscription-container {
  max-width: 1200px;
  margin: 80px auto;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Titre principal */
.subscription-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 700;
  text-align: center;
  color: var(--text-color);
  position: relative;
}

/* Barre colorée sous le titre */
.subscription-title::after {
  content: "";
  display: block;
  width: 120px;
  height: 4px;
  background: var(--primary-color);
  margin: 0.5rem auto 0;
  border-radius: 2px;
}

/* Section des plans d'abonnement */
.subscription-plans {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: center;
}

/* Carte d’un plan d’abonnement */
.subscription-plan {
  background: var(--secondary-color);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  width: 280px;
  max-width: 100%;
  padding: 2rem;
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  position: relative;
}

/* Survol plan : légère élévation + accentuation de la shadow */
.subscription-plan:hover {
  transform: translateY(-6px);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.5);
}

/* Titre du plan */
.subscription-plan h3 {
  font-size: 1.4rem;
  margin-bottom: 0.75rem;
  color: var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Sous-titre ou description brève */
.subscription-plan .plan-description {
  color: var(--secondary-text-color);
  font-size: 0.95rem;
  margin-bottom: 1rem;
}

/* Prix */
.subscription-plan .price {
  font-size: 1.8rem;
  color: var(--accent-color);
  margin: 1rem 0;
  font-weight: 600;
}

/* Bouton "S'abonner" */
.subscription-plan .btn-subscribe {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.8rem 1.5rem;
  background: var(--primary-color);
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

/* Hover du bouton */
.subscription-plan .btn-subscribe:hover {
  background-color: #1565c0; /* Un bleu plus sombre */
  transform: scale(1.02);
}

/* Animation douce sur la carte quand on la survole */
@keyframes floatUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}
.subscription-plan:hover {
  animation: floatUpDown 1.5s ease-in-out infinite;
}

/* Animation slideIn */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in {
  animation: slideIn 0.8s ease forwards;
  /* 0.8s = durée de l’animation, adjust as you like */
}

/* La liste des features */
.plan-features {
  list-style: none;
  margin: 1rem 0;
  padding: 0;
  text-align: left;
  font-size: 0.9rem;
}

.plan-features li {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  content: none;
  margin-right: 0;
}

.best-value-ribbon {
  position: absolute;
  top: 0; /* ou -10px, selon votre goût */
  right: 0; /* ou -10px, selon votre goût */
  background: var(--accent-color);
  color: #000; /* Contraste sur fond doré */
  padding: 0.5rem 1rem;
  font-weight: bold;
  transform: rotate(10deg) translate(20%, -50%);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 10; /* pour passer devant la carte */
  border-radius: 3px;
}

.btn-trial {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.8rem 1.5rem;
  background: var(--primary-color);
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.feature-icon {
  /* taille un peu plus grande */
  font-size: 1.1rem;

  /* espace à droite */
  margin-right: 0.4rem;

  /* Couleur ? On peut forcer la même ou un color plus clair */
  color: var(--accent-color);
}

/* === Comparaison des fonctionnalités (tableau) === */

/* Conteneur du tableau */
.comparison-container {
  margin-top: 3rem; /* espace au-dessus du tableau */
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; /* on centre le titre */
}

/* Titre du tableau */
.comparison-title {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  position: relative;
}

/* Table en lui-même */
.comparison-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--secondary-color);
  color: var(--text-color);
  border-radius: 8px;
  overflow: hidden; /* si on veut un arrondi global */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

/* En-tête */
.comparison-table thead th {
  background-color: var(--primary-color);
  color: #fff;
  font-weight: 600;
  padding: 1rem;
  text-transform: uppercase;
}

/* Lignes du corps */
.comparison-table tbody tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Cellules */
.comparison-table td,
.comparison-table th {
  padding: 0.8rem 1rem;
  text-align: center;
}

/* Lignes alternées : on peut si on veut */
.comparison-table tbody tr:nth-of-type(even) {
  background-color: #2f3145; /* un gris un peu plus clair */
}
.btn-subscribe:disabled {
  background-color: #777; /* Un gris */
  cursor: not-allowed; /* Le curseur "interdit" */
  opacity: 0.8; /* Léger effet de "désactivé" */
}

.btn-subscribe:disabled:hover {
  background-color: #a37800;
}
/* Gérer le responsive */
@media (max-width: 768px) {
  .comparison-container {
    display: none;
  }
  .comparison-table thead {
    display: none; /* on masque l’en-tête pour un layout adaptatif */
  }
  .comparison-table,
  .comparison-table tbody,
  .comparison-table tr,
  .comparison-table td {
    display: block; /* on repasse en “bloc” */
    width: 100%;
  }
  .comparison-table tbody tr {
    margin-bottom: 1.5rem;
    border-bottom: 2px solid var(--primary-color);
    background-color: var(--secondary-color);
  }
  .comparison-table td {
    text-align: left;
    padding: 0.5rem 1rem;
    position: relative;
  }
  .comparison-table td::before {
    content: attr(data-label);
    font-weight: 600;
    display: block;
    margin-bottom: 0.3rem;
    color: var(--accent-color);
  }
  .subscription-plans {
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 1rem;
  }
  .subscription-plan {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  .feature-icon {
    font-size: 1.1rem;

    /* espace à droite */
    margin-right: 0.4rem;

    /* Couleur ? On peut forcer la même ou un color plus clair */
    color: var(--accent-color);
  }
}
