/* ------------------------------
   Les réglages de base restent inchangés
------------------------------ */
:root {
  --display-bg-green: #00a000;
  --text-color-white: #ffffff;
  --font-family: 'Poppins', sans-serif;
  --transition-default: 0.3s ease;
  --glow-color-intense: rgba(255, 215, 0, 0.8);
  --glow-color-fade: rgba(255, 215, 0, 0.1);
}

/* =========================================
   Conteneur principal
========================================= */
.display-messages-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: var(--display-bg-green);
  color: var(--text-color-white);
  font-family: var(--font-family);
  overflow: hidden;
}

/* =========================================
   Colonne de gauche : liste des messages
========================================= */
.messages-left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;           /* occupe toute la hauteur */
  width: 300px;
  background-color: #111;
  overflow-y: auto;
  box-sizing: border-box;
  transition: width 0.3s ease, padding 0.3s ease;
  /* etc... */
}

.messages-right {
  /* Cette zone s’affiche “en dessous” (couvrant toute la page),
     tandis que la .messages-left est par-dessus en absolu sur la gauche. */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center;     /* Centre verticalement */
  flex-direction: column;  /* si besoin */
  /* tu peux mettre un background-color si tu veux voir cette zone */
}

/* Quand la colonne est repliée */
.messages-left.collapsed {
  width: 30px;          /* Devient très étroite */
  padding: 20px 0 20px 0;  /* On réduit le padding horizontal (ex: plus de padding sur les côtés) */
  overflow: hidden;     /* Cache le contenu qui déborde */
}

/* Option : masquer le texte interne quand c'est collapsed (ex. opacité 0)
   Tu peux le faire en CSS pur (nth-child) ou en React conditionnel. Par exemple :
*/
.messages-left.collapsed .messages-title,
.messages-left.collapsed .messages-list,
.messages-left.collapsed .delete-button {
  display: none; /* le titre, la liste et boutons disparaissent */
}

/* Titre "Liste des Messages" */
.messages-title {
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

/* Conteneur listant tous les messages */
.messages-list {
  display: flex;
  flex-direction: column;
  gap: 10px;   /* espace vertical entre les messages */
}

/* Chaque "carte" (bloc) d'un message dans la liste */
.message-card-left {
  background-color: #222;        /* Fond un peu plus clair que #111 */
  border: 1px solid #333;        /* Légère bordure */
  border-radius: 6px;
  padding: 15px;
}


/* Le pseudo du sender */
.message-card-left-sender {
  color: #1E88E5; /* Un bleu un peu vif */
  font-weight: bold;
  margin-bottom: 5px;
}

/* Le contenu du message */
.message-card-left-content {
  color: #fff;
  font-size: 0.95rem;
  line-height: 1.4;
  margin-bottom: 10px;
}

/* Bouton de suppression */
.delete-button {
  background-color: #ff5555;
  color: #fff;
  border: none;
  padding: 6px 12px;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.delete-button:hover {
  background-color: #ff3333;
}

.delete-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #ff9999;
}
/* ------------------------------
   La carte principale
   On retire la couleur de fond ici pour que la lueur ne soit pas cachée
------------------------------ */
.message-card {
  position: relative;         /* Pour positionner le ::before */
  border-radius: 8px;
  width: 600px;
  font-size: 2rem;
  overflow: visible;          /* Permet à la lueur de dépasser */
  opacity: 0;
  animation: fadeInUp 0.8s forwards;
  /* background-color: #000; -> RETIRÉ ici */
}

/* ------------------------------
   Le conteneur interne qui porte le fond noir
------------------------------ */
.message-card__inner {
  border-radius: 8px;         /* Même arrondi que la carte */
  padding: 20px 30px;
  max-height: 300px;
  overflow-y: auto;
  position: relative;         /* Pour superposer le contenu par-dessus la lueur */
  z-index: 2;
  background-color: #000;     /* Le fond noir est appliqué ici */
}

/* ------------------------------
   Les textes restent inchangés
------------------------------ */
.message-sender {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #1E88E5;
}

.message-content {
  font-size: 1.8rem;
  line-height: 1.2;
  color: #fff;
  white-space: pre-wrap;       /* Permet de conserver les retours à la ligne existants et de passer à la ligne si nécessaire */
  word-break: break-word;      /* Force le passage à la ligne pour les longs mots ou chaînes sans espaces */
  /* Vous pouvez aussi utiliser overflow-wrap: break-word; si vous préférez */
}

/* Conteneur global pour le guide (centré verticalement et horizontalement) */
.cropping-guide-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Augmentation de la zone pour offrir une marge autour de la card */
.cropping-guide-square {
  width: 650px;         /* Zone agrandie (par rapport à la card de 600px) */
  height: 650px;
  border: 2px dashed #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;     /* Masque tout débordement de la card */
}


/* Texte explicatif positionné en dessous du carré */
.cropping-guide-text {
  margin-top: 10px;
  color: var(--text-color-white);
  font-size: 1rem;
  text-align: center;
}

.fixed-top-right {
  position: fixed;
  top: 10px;   /* ajustez si besoin */
  right: 10px; /* ajustez si besoin */
  z-index: 9999;
  background: #ff9800; /* Couleur de fond au choix */
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 6px;
  font-weight: 500;
  /* Ajoutez d’autres styles si vous voulez */
}

.fixed-top-right:hover {
  opacity: 0.8; /* petit effet hover */
}

/* ------------------------------
   Animations
------------------------------ */
/* Le conteneur qui gère le déplacement horizontal */

/* Animation combinée sur deux axes pour un mouvement aléatoire */

/* Conteneur pour l'animation horizontale */
.floating-wrapper {
  animation: floatX 5s ease-in-out infinite;
}

/* Élément pour l'animation verticale */
.floating-card {
  animation: floatY 7s ease-in-out infinite;
}

/* Déplacement horizontal (vous pouvez ajuster les valeurs si besoin) */
@keyframes floatX {
  0%   { transform: translateX(0); }
  25%  { transform: translateX(10px); }
  50%  { transform: translateX(-10px); }
  75%  { transform: translateX(5px); }
  100% { transform: translateX(0); }
}

/* Déplacement vertical */
@keyframes floatY {
  0%   { transform: translateY(0); }
  25%  { transform: translateY(-8px); }
  50%  { transform: translateY(8px); }
  75%  { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeOutDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

/* ------------------------------
   Animation de disparition pour la carte
------------------------------ */
.message-card.fade-out {
  animation: fadeOutDown 0.8s forwards;
}

/* ------------------------------
   Responsivité
------------------------------ */
@media (max-width: 600px) {
  .message-card {
    width: 90%;
  }
  .message-card__inner {
    font-size: 1.5rem;
  }
}

@media (max-width: 400px) {
  .message-card__inner {
    font-size: 1.2rem;
  }
}