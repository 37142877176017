/* UserMenu.css */

/* Conteneur principal du menu utilisateur */
.user-menu-container {
    position: relative;
    display: inline-block; /* on pourra le placer côte à côte avec d’autres éléments */
    z-index: 999999;
    
  }
  
  /* Le cercle qui affiche les initiales */
  .user-menu-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #1E88E5;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    position: relative; /* pour positionner le menu déroulant */
    transition: background-color 0.2s ease;
    
  }
  
  .user-menu-circle:hover {
    background-color: #1864a6;
  }
  
  /* Le menu déroulant */
  .user-menu-dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #2222226e;
    backdrop-filter: blur(8px);
    border: 1px solid #444;
    border-radius: 5px;
    padding: 10px;
    min-width: 180px;
    opacity: 0;
    transform: translateX(20px); /* Décalage à droite */
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 9999;
    pointer-events: none;
    display: flex;
    flex-direction: column;
}

/* Quand le menu est affiché */
.user-menu-dropdown.show {
    opacity: 1;
    transform: translateX(0); /* Position normale */
    pointer-events: auto;
    
}
  
  /* Nom d’utilisateur en haut du menu */
  .user-menu-username {
    display: flex;
    margin-bottom: 8px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
  }
  
  /* Boutons de menu en pile */
  .user-menu-dropdown button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    width: 100%;
    margin-bottom: 10px;
    background: #333;
    color: #fff;
    border: none;
    padding: 8px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }


.user-menu-dropdown .menu-button {
  background: #333;
  color: #fff;
  /* autres styles spécifiques */
}
  
  .user-menu-dropdown button:hover {
    background-color: #444;
  }
  
  /* On peut personnaliser la taille des boutons */
  .user-menu-dropdown button:last-child {
    margin-bottom: 0;
  }

  .user-menu-dropdown .logout-button {
    background-color: #8B0000;
    color: #fff;
    border: none;
    padding: 8px;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .user-menu-dropdown .logout-button:hover {
    background-color: #A52A2A;
  }

  @media (max-width: 600px) {
    .user-menu-circle {
        width: 50px; /* Nouvelle largeur */
        height: 50px; /* Nouvelle hauteur */
        font-size: 1.2rem; /* Ajustement de la taille de la police si nécessaire */
    }

    /* Optionnel : ajuster la taille du menu déroulant si nécessaire */
    .user-menu-dropdown {
        min-width: 200px;
    }

    /* Optionnel : ajuster les boutons dans le menu déroulant */
    .user-menu-dropdown button {
        font-size: 1rem;
        padding: 10px;
    }
}

.skeleton-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  animation: skeleton-pulse 1.5s ease-in-out infinite;
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.4;
  }
}