/* src/components/PasswordCriteria.css */
.password-criteria {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  
  .password-criteria ul {
    list-style: none;
    padding: 0;
  }
  
  .password-criteria li.met {
    color: green;
  }
  
  .password-criteria li.unmet {
    color: red;
  }
  .password-criteria p {
    margin: 0 0 5px 0;
    font-weight: 500;
  }
  
  .password-criteria li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 0.9rem;
  }
  
  .password-criteria li::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 50%;
  }
  
  .password-criteria li.met::before {
    background-color: #4caf50; /* Vert */
  }
  
  .password-criteria li.unmet::before {
    background-color: #ff4b4b; /* Rouge */
  }